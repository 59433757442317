import React from 'react'
import DashboardLayout from '../Layout'
import Seo from '../Utils/Seo'
import { Box, Flex, Select, Table, Tbody, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import Headers from '../Components/Header'
import { useState } from 'react'
import { useEffect } from 'react'
import {  GetStaffReservationAPI } from '../Utils/ApiCalls'
import TableRow from '../Components/TableRow'
import moment from 'moment'
import ApproveReservation from '../Components/ModalComponent/ApproveReservation'
import { pageSizeArr } from '../Utils/ApiConfig'
import Pagination from '../Components/Pagination'
import Preloader from '../Components/Preloader'

const ReservationList = () => {

  const [Data, setData] = useState([]);
  const [SingleItem, setSingleItem] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [IsLoading, setIsLoading] = useState(true);


  const [CurrentPage, setCurrentPage] = useState(1);
    console.log("currentpage", CurrentPage)
    const [PostperPage, setPostperPage] = useState(10);

    //get current post

    const indexOfLastSra = CurrentPage * PostperPage;
    const indexOfFirstSra = indexOfLastSra - PostperPage;
    const PaginatedData = Data.slice(indexOfFirstSra, indexOfLastSra);
    //change page 
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }


    const handleRowsPerPageChange = (event) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setPostperPage(newRowsPerPage);
      setCurrentPage(1);
  };


  const getReservations = async () => {

    try {
      const result = await GetStaffReservationAPI();
      console.log("reservation", result)

      if (result.status === 200) {
        setData(result.data)
        setIsLoading(false)
      } else {

      }
    } catch (error) {

      // toast.error(error.message);
    }
  }


  const openModal = (item)=>{
    setSingleItem(item)
    onOpen()
  }

  useEffect(() => {
    getReservations()
  }, [isOpen]);


  return (
    <DashboardLayout>
      <Seo title='Reservation List ' description='Reservation list for Card empirion' />


      {
      IsLoading && (
        
        <Preloader/>
      )
    }
     

      <Box mt="12px" px="15px" py="15px" bg={"#fff"} rounded="8px" mb="32px">
        <Headers text={"Reservation List"} mt="12px" />


        <Box display="flex" alignItems="center"  justifyContent={"flex-end"} fontSize={{ base: "12px" }} color="#242424" >
                                Rows per page:
                                <Select  value={PostperPage} borderColor= "#242424"  onChange={handleRowsPerPageChange} width="auto" ml="1rem"  fontSize={{ base: "12px" }}
                                sx={{        "&:focus": {
                                            borderColor: "#242424",
                                            boxShadow: "none",
                                        },
                                    }}
                                   
                                >
                                    {
                                        pageSizeArr.map(item => (

                                            <option value={item}>{item}</option>
                                        ))
                                    }

                                </Select>
                            </Box>

        <Box overflowX="auto" mt="32px">

          <Table variant='striped' maxWidth="100%" overflowX={"auto"} mt="10px" whiteSpace="nowrap">
            <Thead>
              <Tr bg={"gray.gray600"} ml="0">
                <Th color="#fff" fontSize={"10px"}>S/N</Th>
                <Th color="#fff" fontSize={"10px"}>User</Th>
                <Th color="#fff" fontSize={"10px"}>Reservation No</Th>
                <Th color="#fff" fontSize={"10px"}>Embassy</Th>
                <Th color="#fff" fontSize={"10px"}>status</Th>
                <Th color="#fff" fontSize={"10px"}>Created</Th>
                <Th color="#fff" fontSize={"10px"}>Take action</Th>

              </Tr>
            </Thead>
            <Tbody pl="10px important">
              {
                PaginatedData?.map((item, i) => (
                  <TableRow
                    type="reservation list"
                    key={i}
                    sn={i + 1}
                    name={`${item.user_data?.first_name} ${item.user_data?.last_name}`}
                    reservationNo={item.reservation_number}
                    embassy={`${item.embassy_data?.address}, ${item.embassy_data?.country}`}
                    status={item.status}
                    created={moment(`${item.created_at}`).format("LLL")}
                    onClick={() => openModal(item)}



                  />
                ))
              }





            </Tbody>

          </Table>

          <Pagination postPerpage={PostperPage} currentPage={CurrentPage} totalPosts={Data.length} paginate={paginate} />

        </Box>


      </Box>
      <ApproveReservation isOpen={isOpen} onClose={onClose} singleDetails={SingleItem} />

    </DashboardLayout>
  )
}

export default ReservationList