import { Box, Flex, Td, Text, Tr } from '@chakra-ui/react'
import React from 'react'
import Button from './Button'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { BsCheck2Square, BsFillPencilFill } from 'react-icons/bs'
import TableBtn from './TableBtn'

export default function TableRow({ type, serviceType,email, sn, embassy, status, initials, created, reservationNo, name, onClick, country, address }) {

    const nav = useNavigate()
    return (
        <Tr fontSize={"11px"} cursor={"pointer"} color="blue.blue600" >


            {
                type === "make reservation" && (
                    <>

                        <Td >{sn}</Td>
                        <Td >{reservationNo}</Td>
                        <Td >{embassy}</Td>
                        <Td>
                            <Box textAlign={'center'} px={"15px"} textTransform={"capitalize"} rounded="8px" py="5px" w='auto' background='transparent' border={status === "pending" ? "1px solid red" : status === "rejected" ? " 1px solid red" : "1px solid green"}
                                color={status === "pending" ? "red" : status === "rejected" ? "red" : "green"}
                            >
                                {status}
                            </Box>

                        </Td>
                        <Td >{created}</Td>

                    </>
                )
            }
            {
                type === "embassy list" && (
                    <>

                        <Td >{sn}</Td>
                        <Td >{address}</Td>
                        <Td >{country}</Td>
                        <Td >{initials}</Td>
                        <Td >{created}</Td>
                        <Td>
                            <TableBtn title={"edit"} onClick={onClick} />
                        </Td>

                    </>
                )
            }
            {
                type === "manage staff" && (
                    <>

                        <Td >{sn}</Td>
                        <Td >{email}</Td>
                        <Td >{embassy}</Td>
                        <Td >{status}</Td>
                        <Td >{created}</Td>
                        <Td>
                            <TableBtn color="red" title={status === "Active" ? "disable": "enable"} onClick={onClick} />
                        </Td>

                    </>
                )
            }
            {
                type === "user list" && (
                    <>

                        <Td >{sn}</Td>
                        <Td >{email}</Td>
                        <Td >{status}</Td>
                        <Td >{created}</Td>
                        <Td>
                            <TableBtn color="red" title={status === "Active" ? "disable": "enable"} onClick={onClick} />
                        </Td>

                    </>
                )
            }
            {
                type === "manage admin" && (
                    <>

                        <Td >{sn}</Td>
                        <Td >{email}</Td>
                        <Td >{embassy}</Td>
                        <Td >{status}</Td>
                        <Td >{created}</Td>
                        <Td>
                            <TableBtn color="red" title={"disable"} onClick={onClick} />
                        </Td>

                    </>
                )
            }

            {
                type === "reservation list" && (
                    <>

                        <Td >{sn}</Td>
                        <Td textTransform={"capitalize"}>{name}</Td>
                        <Td >{reservationNo}</Td>
                        <Td >{embassy}</Td>
                        <Td>
                            <Box textAlign={'center'} px={"15px"} textTransform={"capitalize"} rounded="8px" py="5px" w='auto' background='transparent' border={status === "pending" ? "1px solid orange" : status === "rejected" ? " 1px solid red" : "1px solid green"}
                                color={status === "pending" ? "orange" : status === "rejected" ? "red" : "green"}
                            >
                                {status}
                            </Box>

                        </Td>
                        <Td >{created}</Td>
                        <Td >
                            {
                                status === "pending" ? (
                                    <Box cursor="pointer" bg="blue" color="#fff" w='auto' textAlign={"center"} p="8px" rounded="8px" onClick={onClick}>Approve</Box>

                                ) : (

                                    status === "rejected" ? (

                                        <Box cursor="pointer" fontSize={"16px"} bg={"red"} display={"flex"} justifyContent={"center"} color="#fff" textAlign={"center"} py="8px" rounded="8px" > <BsCheck2Square /> </Box>
                                    ) : (

                                        <Box cursor="pointer" fontSize={"16px"} bg={"green"} display={"flex"} justifyContent={"center"} color="#fff" textAlign={"center"} py="8px" rounded="8px" > <BsCheck2Square /> </Box>
                                    )

                                )
                            }

                        </Td>

                    </>
                )
            }


        </Tr>

    )
}
