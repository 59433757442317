import { Text } from '@chakra-ui/layout'
import React from 'react'

export default function Headers({text,mt}) {
  return (
    <Text textTransform={"capitalize"} mt={mt} pos="relative" fontSize={"16px"} letterSpacing={"1px"} _before={{h: "3px", pos: "absolute", bottom: "0", left: "0",  bg: "blue.blue500", w: "48px", top: "25px", content: '" "'}} fontWeight={"500"} color="blue.blue600">{text}</Text>
  )
}



