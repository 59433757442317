import { Box, Flex, HStack, Spacer, Text } from "@chakra-ui/react";
import React from "react";

export default function DashboardCard({
  color = "gray.gray600",
  title,
  value,
  icon,
  lg = "24.2%",
  md = "49%",
  unit = " ",
}) {
  return (
    <Box
      bg={color}
      cursor={"pointer"}
      transition={"0.5s "}
      _hover={{ bg: "blue.blue600" }}
      boxShadow={"4px 6px 12px 3px #B5B5B5 "}
      w={["100%", "100%", `${md}`, `${md}`, `${lg}`]}
      mt={["15px", "10px", "10px", "10px", "2px"]}
      px="10px"
      py="15px"
      rounded="8px"
      color={"#fff"}
    >
      <Text
        textTransform={"capitalize"}
        fontSize={"14px"}
        fontWeight={"500"}
        color="#fff"
      >
        {title}
      </Text>

      <HStack mt="10px">
        <Flex
          justifyContent={"center"}
          bg="#ddd"
          color={color}
          p="10px"
          rounded="100"
        >
          <Box fontSize={"35px"}>{icon}</Box>
        </Flex>

        <Spacer />
        <Box textAlign={"center"}>
          <Text
            textTransform={"capitalize"}
            fontSize={"18px"}
            fontWeight={"700"}
            color="#fff"
          >
            {value}
          </Text>
          <Text
            textTransform={"capitalize"}
            fontSize={"13px"}
            fontWeight={"500"}
            color="#fff"
          >
            {unit}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
}
