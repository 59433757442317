import { Text } from '@chakra-ui/react'
import React from 'react'

export default function NavItem({text,link}) {
  return (
    <Text fontSize={"15px"} onClick={() => window.location = `${link}`} px="20px" textTransform={"Capitalize"} transition={"0.3s ease-in"} _hover={{color: "blue.blue600"}}>
      {text}
    </Text>
  )
}
