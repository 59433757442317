import React from "react";
import Navbar from "../OuterLayout/NavBar";
import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import Footer from "../OuterLayout/Footer";

export const Terms = () => {
  return (
    <Box>
      <Navbar />
      <Box>
        <Text
          textTransform={"uppercase"}
          color={"blue.blue500"}
          mt={{base:"70px", md:"80px"}}
          textAlign={"center"}
          fontSize={{ base: "16px", md: "18px", lg: "24px" }}
          fontWeight={"700"}
        >
          No Refund Policy
        </Text>
      </Box>
      <Box w={{base:"80%", md:"70%", lg:"50%"}} margin={"auto"} my="20px">
        <UnorderedList
          w="100%"
          display={"flex"}
          flexDirection={"column"}
          // justifyContent={"center"}
          // alignItems={"center"}
          gap="10px"
          fontSize={{ base: "12px", md: "14px", lg: "18px" }}
        >
          <ListItem>
            Dannon Investment & Mgt Consultancy has no refund/replacement policy
            because the services are sold “as is.”
          </ListItem>
          <ListItem>
            Unless required by law, we do not provide refunds for Services
            received or not received, accidental purchases, medical conditions,
            or any other similar reason or event.
          </ListItem>
          <ListItem>
            There will be no refunds given after payment has been made to the
            company prior to the use of the service (s).
          </ListItem>
          <ListItem>
            At any time, no refunds will be issued for partially utilized
            services.
          </ListItem>
          <ListItem>
            If you are dissatisfied with our service(s), kindly contact our
            Support Team at support@dannongroup.com.ng
          </ListItem>
          <ListItem>
            If you are dissatisfied with our service(s), kindly contact our
            Support Team at support@dannongroup.com.ng
          </ListItem>
        </UnorderedList>
      </Box>
      <Footer/>
    </Box>
  );
};
