import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Button from '../Button'
import { ApproveReservationAPI, CreateEmbassyApi, CreateReservationAPI, EditEmbassyApi, GetCountries, GetEmbassy } from '../../Utils/ApiCalls';
import { toast } from 'react-toastify';
import Input from '../Input';


export default function CreateEmbassy({ isOpen, onClose, singleDetails }) {

    const [Payload, setPayload] = useState({
        country: "",
        address: "",
        initials: "",

    });

    const [Countries, setCountries] = useState([]);


    const [Loading, setLoading] = useState(false);


    const handlePayload = (e) => {
        setPayload({
            ...Payload, [e.target.id]: e.target.value
        })



    }




    const Create = async () => {
        let initial = Countries.filter(item => item.country === Payload.country)

        setLoading(true)
        try {

            const result = await CreateEmbassyApi({
                country: Payload.country,
                address: Payload.address,
                initials: initial[0].iso3

            });
            console.log("CreateEmbassyApi", result);
            setLoading(false)
            if (result.status === 200) {

                toast.success("Embassy Created Successfully");

                onClose()
                setPayload({
                    country: "",
                    embassy: "",
                    initial: "",
                })

            } else {

            }

        } catch (error) {
            setLoading(false)

            toast.error(error.message);
        }


    }
    const EditEmbassy = async () => {
        let initial = Countries.filter(item => item.country === Payload.country)

        setLoading(true)
        try {

            const result = await EditEmbassyApi({
                country: Payload.country,
                address: Payload.address,
                initials: initial[0].iso3

            },singleDetails?.id);
            console.log("EditEmbassyApi", result);
            setLoading(false)
            if (result.status === 200) {

                toast.success("Embassy Edited Successfully");

                onClose()
                setPayload({
                    country: "",
                    embassy: "",
                    initial: "",
                })

            } else {

            }

        } catch (error) {
            setLoading(false)

            toast.error(error.message);
        }


    }
    const getCountries = async () => {

        try {
            const result = await GetCountries();
            console.log("countries", result.data)
            if (result.status === 200) {
                setCountries(result.data.data)
            } else {

            }
        } catch (error) {

            // toast.error(error.message);
        }
    }




    useEffect(() => {
        getCountries()

        setPayload({
            country: singleDetails?.country,
            address: singleDetails?.address,
            initials: singleDetails?.initials,
        })
    }, [isOpen]);


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={"lg"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{singleDetails ? "Edit Embassy" : "Create embassy"}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Box>

                        <Stack>


                            <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select country' fontWeight={"400"} fontSize={Payload.country != "" ? "16px" : "12px"} id='country' value={Payload.country} onChange={handlePayload}>
                                {
                                    Countries?.map((item, i) => (

                                        <option key={i} value={`${item.country}`}>{item.country}</option>

                                    ))
                                }


                            </Select>

                            <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select address' fontWeight={"400"} fontSize={Payload.address !== "" ? "16px" : "12px"} id='address' value={Payload.address} onChange={handlePayload}>
                                {
                                    Countries?.filter(item => item.country === Payload.country)[0]?.cities.map((item, i) => (


                                        <option key={i} value={`${item}`}>{item}</option>


                                    ))
                                }

                            </Select>

                        </Stack>





                    </Box>

                </ModalBody>

                <ModalFooter>
                    {
                        singleDetails ? (
                            <Button isLoading={Loading} onClick={EditEmbassy}>
                               Update Embassy
                            </Button>
                        ) : (
                            <Button isLoading={Loading} onClick={Create}>
                                Create Embassy
                            </Button>
                        )
                    }


                </ModalFooter>
            </ModalContent>

        </Modal>
    )
}
