export const UserDetails = JSON.parse(sessionStorage.getItem("UserDetails"));
 

export const activeScreen = (route, pathname) => {
  if (route === pathname) {
    return true;
  } else {
    return false;
  }
};

export const isSuperAdmin = () => {
  //check for Admin user here and set the state accordingly
  if (UserDetails?.role === "superuser") {
    return true;
  } else {
    return false;
  }
};

export const isAdminUsers = () => {
  //check for Admin user here and set the state accordingly
  if (UserDetails?.role === "superuser" || UserDetails?.role === "admin") {
    return true;
  } else {
    return false;
  }
};



export const isAdminUser = () => {
  //check for Admin user here and set the state accordingly
  if (UserDetails?.role === "admin") {
    return true;
  } else {
    return false;
  }
};

export const isUser = () => {
  //check for user here and set the state accordingly
  if (UserDetails?.role === "user") {
    return true;
  } else {
    return false;
  }
};

export const isStaff = () => {
  //check for user here and set the state accordingly
  if (UserDetails?.role === "staff") {
    return true;
  } else {
    return false;
  }
};
