import { Box } from "@chakra-ui/react";
import React from "react";


export default function TableBtn({title, onClick, color= "gray.gray600"}) {
    return (
        <Box display="flex" mr="10px" justifyContent="center" alignItems="center" cursor={"pointer"} transition={"0.6s ease"} onClick={onClick}
         textAlign={'center'} px={"15px"} textTransform={"capitalize"} rounded="8px"
          py="5px" w='auto' background='transparent' _hover={{ background: color, color: "#fff" }} borderColor={color} borderWidth={"1px"} 
            color={color}
        >
           {title}
        </Box>
    )
}
