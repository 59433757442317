import React from 'react'
import DashboardLayout from '../Layout'
import Seo from '../Utils/Seo'
import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import Headers from '../Components/Header'
import SearchBar from '../Components/SearchBar'
import { useState } from 'react'
import UserInstance from '../Components/UserInstance'
import { ConvertBase64API, GetInstanceAPI } from '../Utils/ApiCalls'
import { toast } from 'react-toastify'
import Input from '../Components/Input'
import { MdCancel } from 'react-icons/md'
import { BsFillCloudUploadFill } from 'react-icons/bs'
import Button from '../Components/Button'
import exportFromJSON from 'export-from-json'
import moment from 'moment/moment'

export default function BiometricsUpload() {
    const [ReservationNo, setReservationNo] = useState("")

    console.log("reservation no", ReservationNo)
    const [Data, setData] = useState(null)
    const [DataConverted, setDataConverted] = useState(null)

    console.log("Data log", Data   )
    const [Loading, setLoading] = useState(false)



    
    const getConvertedImg = async () => {

        try {
            
            const result = await ConvertBase64API({
                image: Data?.instance.image_data?.image,
                signature: Data?.instance.profile_data.signature,
                qr: Data?.instance.profile_data.signature
            });
            
            if (result.status === 200) {
                
                console.log("getConvertedImg", result)
                setDataConverted(result.data)
              
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            toast.error(error.message);
        }
    }



    const getInstanceOfUser = async () => {

        try {
            setLoading(true)
            const result = await GetInstanceAPI(ReservationNo);
            console.log("getInstanceOfUser", result)

            if (result.status === 200) {
                setData(result.data)
                setLoading(false)

                setTimeout(() => {
                    getConvertedImg()
                }, 2000);
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            toast.error(error.message);
        }
    }


    

    const [Doc, setDoc] = useState(null);
    const [DocType, setDocType] = useState("");
    const handleUpload = (e) => {
        setDoc(e.target.files[0])
    }

    const PrintCard = () => {
        console.log("DataConverted",DataConverted)
        const data = [{
            surname: Data?.instance.profile_data.last_name,
            given_name: Data?.instance.profile_data.first_name,
            gender: Data?.instance.profile_data.gender,
            date_of_birth: Data?.instance.profile_data.dob,
            height: Data?.instance.profile_data.height,
            card_number: Data?._meta.unique_id,
            place_of_birth: Data?.instance.profile_data.country_birth,
            place_of_issuance: Data?.reservation_data.embassy_data.country,
            nin: Data?.instance.profile_data.nin,
            signature:  DataConverted?.signature,
            qr_code_image:  DataConverted?.qr,
            emergency_contact:  Data?.instance.profile_data.kin_phone,
            date_of_issuance: moment(Data?._meta.issued_date).format("LL"),
            date_of_expiry: moment(Data?._meta.expiry_date).format("LL"),
            profile_image: DataConverted?.image
            
        }]
        
        const fileName = 'BiometricPrint'
        const exportType = exportFromJSON.types.csv

        exportFromJSON({ data, fileName, exportType })
    }

    return (
        <DashboardLayout>

            <Seo title='Biometric upload' description='Card empirion Biometric upload' />

            <Box bg={"#fff"} p="20px" mt="32px" rounded={"8px"}>
                <Headers text={"Biometric Upload"} />

                <SearchBar loadingText={"searching..."} isLoading={Loading} onClick={getInstanceOfUser} handleSearch={(e) => setReservationNo(e.target.value)} value={ReservationNo} placeholder={"search for reservation number"} btnText={"search"} />

                {
                    !Data || Data && (
                        <>
                            <UserInstance Data={Data.instance} />
                            <Flex justifyContent="center" alignItems="center">
                                <Box w={["90%", "90%", "70%", "70%", "60%"]} rounded="8px" py="15px" px="20px" alignSelf={"center"}>

                                    <Input onChange={handleUpload} type="file" id="bulk-assign" hidden />
                                    <label for="bulk-assign">

                                        <Box mt="32px" borderStyle={"dotted"} borderWidth={"2px"} borderColor={Doc !== null ? "gray.gray600" : "#242424"} py="10%" cursor={"pointer"}>
                                            <Flex justifyContent={"center"} color="gray.gray600" fontSize={"30px"}><BsFillCloudUploadFill /></Flex>
                                            <Text mt="7px" fontWeight={"600"} textAlign={"center"}>Upload File (CSV/XLSX)</Text>
                                            {
                                                Doc !== null && (
                                                    <HStack justifyContent={"center"}>
                                                        <Box onClick={() => setDoc(null)} color={"red"} fontSize={"20px"}><MdCancel /></Box>
                                                        <Text textAlign={"center"}>{Doc?.name}</Text>
                                                    </HStack>
                                                )
                                            }

                                        </Box>

                                    </label>
                                    {/* <Select onChange={(e)=>setDocType(e.target.value)} mt="12px" _focus={{ borderColor: "gray.gray600" }} size={"lg"} borderColor={"#242424"} placeholder="Select UploadType Type" fontWeight={"400"} id="uploadType" fontSize={"12px"}>
                        <option value={`Bulk key change`}>Bulk key change</option>
                        <option value={`Bulk free issue`}>Bulk free issue</option>
                        <option value={`Bulk meter`}>Bulk meter</option>
                        <option value={`Bulk clear token`}>Bulk clear token</option>
                        <option value={`Bulk tamper token`}>Bulk tamper token</option>
                        <option value={`Bulk max power`}>Bulk max power</option>
                        
                    </Select> */}


                                    <Flex justifyContent={"space-around"} flexDir={["column", "row"]}>
                                        <Button mt="32px" w={['100%','45%']} disabled={Doc !== null ? false : true}>Upload biometrics</Button>
                                        <Button onClick={PrintCard} mt="32px" w={['100%','45%']} disabled={Doc !== null ? false : true} background='teal'>Proceed to print</Button>
                                    </Flex>
                                </Box>
                            </Flex>

                        </>
                    )
                }


            </Box>
        </DashboardLayout>
    )
}
