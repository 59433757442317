import React from 'react';
import Cards from '../Components/Cards';
import cardData from '../Utils/CardData';
import UserCardData from '../Utils/DummyData'
import { Avatar, Flex } from '@chakra-ui/react';
import { isAdminUser, isUser } from '../Auth';
import UserContent from '../Components/UserContent'
import { AiOutlineUser } from 'react-icons/ai';
import { FaUber } from 'react-icons/fa';
import DashboardCard from './DashboardCard';
import { useEffect } from 'react';
import { AdminDashboardDataApi, AdminStaffProfileX } from '../Utils/ApiCalls';
import { useState } from 'react';
import { toast } from 'react-toastify';

const DashBoardContent = (data) => {

  let sampleData = cardData
  const isAdmin = isAdminUser();

  const [DataAdmin, setDataAdmin] = useState({});

  const newUser = JSON.parse(sessionStorage.getItem("UserDetails"));

  const getAdminDashboard = async () => {
    console.log("check");
    try {
      const result = await AdminDashboardDataApi();
      console.log("getAdminDashboard", result);
      if (result.status === 200) {
        
        setDataAdmin(result.data.detail)

      } else {

      }
    } catch (error) {

      toast.error(error.message);
    }
  }

  useEffect(() => {
    if (newUser.role === "admin") {
      getAdminDashboard()
    } 
  }, []);

  return (
    <>
      {isAdmin && (
        <Flex justifyContent="space-between" mt="22px" flexWrap="wrap">

          <DashboardCard
            key={""}
            color={"#242424"}
            value={DataAdmin.users||0}
            title={"Total users"}
            icon={<FaUber />}

          />
          <DashboardCard
            key={""}
            color={"blue.blue500"}
            value={DataAdmin.reservations||0}
            title={"Total Reservations"}
            icon={<FaUber />}

          />
          <DashboardCard
            key={""}
            color={"#15501C"}
            value={DataAdmin.embassies||0}
            title={"Total embassies"}
            icon={<FaUber />}

          />
          <DashboardCard
            key={""}
            color={"navy"}
            value={DataAdmin.staff||0}
            title={"Total staffs"}
            icon={<FaUber />}

          />

        </Flex>
      )}
    </>
  );
};

export default DashBoardContent;
