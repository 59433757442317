import React, { useEffect, useState } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import Message from "./Message";
// import { GetUserSupportApi } from "../../Utils/ApiCalls";
// import { showToast } from "../../../Utils/ToastTools";
import Form from "./Form";
import DashboardLayout from "../../Layout";
import Seo from "../../Utils/Seo";
import Preloader from "../../Components/Preloader";
import SearchBarr from "../../Components/SearchBarr";

export default function UserSupport() {
  const [formTitle, setFormTitle] = useState("new");
  const [IsLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  // console.log("userSupportMessages", messages);
  const [msg, setMsg] = useState({});
  // console.log(msg);

  const [searchQuery, setSearchQuery] = useState("");

  const [Payload, setPayload] = useState({
    subject: "",
    message: "",
  });

  // console.log(Payload);

  const handlePayload = (e) => {
    setPayload({ ...Payload, [e.target.id]: e.target.value });
  };

  // useEffect(() => {
  //   UserSupportData();
  // }, []);

  // const UserSupportData = async () => {
  //   setIsLoading(true);
  //   try {
  //     let result = await GetUserSupportApi();
  //     // console.log("Res userSupport", result);
  //     if (result.status === 200) {
  //       setMessages(result.data);
  //       setIsLoading(false);
  //     }
  //   } catch (e) {
  //     setIsLoading(false);

  //     showToast({
  //       type: "error",
  //       message: e.message,
  //     });
  //   }
  // };

  const filteredMessages = messages.filter((message) => {
    return message.subject.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <DashboardLayout>
      <Seo title="Support" description="Dannon Verification Support page" />
      {/* {IsLoading && <Preloader />} */}
      <Box
        bgColor="white"
        boxShadow="2px 5px 10px 2px #BDBDBD"
        rounded="4px"
        padding="20px"
        mt="20px"
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={{ base: "column", md: "row" }}
          flexWrap={"wrap"}
        >
          <SearchBarr
            placeholder="Search..."
            rounded="4px"
            text="All"
            btnText="Search"
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          <Text
            fontSize={{ base: "14px", md: "20px" }}
            fontWeight="600"
            mt={{ base: "20px", md: "0px" }}
          >
            {formTitle === "new" ? "New Message" : "Edit Message"}
          </Text>
        </Flex>
        <Box
          display="flex"
          flexDirection={{ base: "column-reverse", md: "row" }}
          mt="10px"
          pb="20px"
        >
          <Message
            setPayload={setPayload}
            // messages={filteredMessages}
            setFormTitle={setFormTitle}
            setMsg={setMsg}
            msg={msg}
            // UserSupportData={UserSupportData}
          />

          <Form
            handlePayload={handlePayload}
            Payload={Payload}
            setPayload={setPayload}
            formTitle={formTitle}
            msg={msg}
            setFormTitle={setFormTitle}
            // UserSupportData={UserSupportData}
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
}
