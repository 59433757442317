import React from 'react'
import DashboardLayout from '../Layout'

const CardPrinting = () => {
  return (
    <DashboardLayout>
        <h1>Card Printing</h1>
    </DashboardLayout>
  )
}

export default CardPrinting