import { Box, Collapse, Flex, Image, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiMenu, BiMenuAltRight } from "react-icons/bi";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { Navlist } from "./NavList";
import NavLink from "./NavLink";
import Logo from '../assest/logo.png'

const Sidebar = ({ open, handleOpen }) => {
  const location = useLocation();

  const List = Navlist(location.pathname);

  const nav = useNavigate();

  const [isOpen, setIsOpen] = useState("");

  

  return (
    <Box mt="10px"  pt="16px"  transition={'0.4s ease'}>
      <Flex
        justifyContent={open === false ? "space-between " : "center"}
        alignItems="center"
        px={["5%", "10%"]}


      >
        {
          open === false && (

            <Image cursor={"pointer"} onClick={()=>nav("/")} w={["30%", "30%"]} src={Logo} alt="Logo" />
          )
        }

        <Box fontSize="32px" cursor="pointer" onClick={handleOpen}>
          {open ? <HiOutlineMenuAlt1 /> : <BiMenu />}
        </Box>


      </Flex>

      <Stack mt="32px" spacing={"20px"} pl="10%">
        {List.map((item, i) => {
          if (item.display === true) {

            return (
              <NavLink
                key={i}
                submenu={item.children}
                icon={item?.icon}
                onClick={() => {
                  if (item.children) {
                    if (item.name === isOpen) {
                      setIsOpen(null);
                    } else {
                      setIsOpen(item.name);
                    }
                  } else {
                    nav(item.link);
                  }
                }}
                isOpen={isOpen}
                active={item.active}
                activeScreen={item.active}
                setIsOpen={setIsOpen}
              >
                {open === false && item.name}
              </NavLink>
            );
          }
          return <></>;
        })}
      </Stack>
    </Box>
  );
};

export default Sidebar;
