import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from '../Components/Dropdown'
import ProfileCard from '../Components/ProfileCard'
import Search from '../Components/Search'
import { Stack } from "@chakra-ui/react";
import NavLinkX from "./NavLinkX";
import {
  Box, Flex, HStack, Image, Spacer, Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent, useDisclosure,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { BiMenuAltLeft } from 'react-icons/bi'
import Notification from '../Components/Notification'
import { Navlist } from "./NavList";
import Logo from '../assest/logo.png'
import { toast } from 'react-toastify';
import { AdminStaffProfileX, UserProfile, UserProfileX } from '../Utils/ApiCalls';
import { useEffect } from 'react';


export default function Navbar() {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const location = useLocation()

  const List = Navlist(location.pathname);

  const pathName = location.pathname.replaceAll("/"," > ").replaceAll("-", " ")


  const nav = useNavigate();

  const [isOpenx, setIsOpen] = useState("");

  const [Data, setData] = useState({});


  
  const getUSerDetails = async ()=>{

    try {
      const result = await UserProfileX();
      console.log("UserProfile", result);
      if (result.status === 200) {
        // setLoading(false);
        sessionStorage.setItem("profileDetails", JSON.stringify(result.data))
        setData(result.data)

      } else {
       
      }
    } catch (error) {
      
      toast.error(error.message);
    }
  }


  

  useEffect(() => {

    getUSerDetails()
   

  }, [])



  return (
    <Box>

      <Flex mt="20px" w="100%" justifyContent={"space-between"} display={["none", "none", "none", "flex", "flex"]}>

        <Box w="50%" mt="15px" textTransform={"capitalize"} fontStyle={"italic"} fontWeight={"bold"}>
        {pathName}
        </Box>

        <Flex w="50%" spacing={"32px"} justifyContent={"flex-end"} align={"flex-start"} >
          {/* <Dropdown /> */}
          {/* <Notification /> */}
          <ProfileCard  />
        </Flex>
      </Flex>

      <Flex bg="#fff" rounded="8px" py="5px" px="3px" mt="20px" w="100%" justifyContent={"space-between"} display={["flex", "flex", "flex", "none", "none"]} >
        <HStack cursor={"pointer"}>
          <Box fontSize={"24px"} onClick={onOpen}>
            <BiMenuAltLeft />
          </Box>
          <Image w={["20%","20%","20%","20%", "20%"]} src={Logo} alt="Logo" />
        </HStack>

        <Flex w="70%" spacing={"32px"} justifyContent={"flex-end"} align={"flex-start"} >
          {/* <Dropdown /> */}
          <ProfileCard />
        </Flex>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}

      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image onClick={()=>nav("/")} w={["30%","20%","20%","20%", "10%"]} src={Logo} alt="Logo" />

          </DrawerHeader>
          <DrawerBody>
            <Stack mt="32px" spacing={"15px"} >
              {List?.filter(item => item.display === true).map((item, i) => {

                return (
                  <NavLinkX
                    key={i}
                    submenu={item.children}
                    icon={item?.icon}
                    onClick={() => {
                      if (item.children) {
                        if (item.name === isOpenx) {
                          setIsOpen(null);
                        } else {
                          setIsOpen(item.name);
                        }
                      } else {
                        nav(item.link);
                      }
                    }}
                    isOpen={isOpenx}
                    active={item.active}
                    activeScreen={item.active}
                    setIsOpen={setIsOpen}
                  >
                    {item.name}
                  </NavLinkX>
                );
              })}
            </Stack>
          </DrawerBody>

          <DrawerFooter>

          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}
