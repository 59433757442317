import React from "react";
import OuterLayout from "../../OuterLayout/Index";
import { Box, Flex, Image, Stack, Text, Textarea } from "@chakra-ui/react";
import homebg from "../../assest/E-Consular Card IMG rock.png";
import contactImg from "../../assest/question.jpg";
import productImg from "../../assest/avatar3.png";
import NavBar from "../../OuterLayout/NavBar";
import Seo from "../../Utils/Seo";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import HeaderTitle from "../../Components/HeaderTitle";
import Input from "../../Components/Input";
import WhyChooseUsCard from "../../Components/WhyChooseUsCard";
import { AiFillCheckSquare, AiFillThunderbolt } from "react-icons/ai";
import { BsFillChatTextFill, BsFillExplicitFill } from "react-icons/bs";
import { FaBaby, FaUser } from "react-icons/fa";
import { MdEmail, MdSubject } from "react-icons/md";
import { useState } from "react";
import { SendMessageAPI } from "../../Utils/ApiCalls";

export default function Home() {
  const nav = useNavigate();

  const [Payload, setPayload] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [Loading, setLoading] = useState(false);
  const [ShowMsg, setShowMsg] = useState(false);

  const handlePayload = (e) => {
    setPayload({ ...Payload, [e.target.id]: e.target.value });
  };

  const sendMessage = async () => {
    setLoading(true);

    try {
      let result = await SendMessageAPI(Payload);
      console.log("result", result);
      if (result.status === 200) {
        setLoading(false);
        setShowMsg(true);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <OuterLayout>

      <Seo title='Homepage' description='Homepage for Card Empirion' />

      <Box minH="100vh"  w="100%"  bg="#ddd"  >


        <Flex justifyContent={"center"} alignItems={"center"} pt={["200px", "2000px", "200px", "230px", "270px",]} textAlign={"center"}>

          <Box w={["90%", "80%", "70%", "60%", "60%"]} color="green">
            <Text
              fontSize={["40px", "48px", "60px", "60px", "75px"]}
              lineHeight={["55px", "60px", "60px", "80px", "80px"]}
              fontWeight={"700"}
              textTransform={"capitalize"}
            >
              E-consular card
            </Text>
          </Box>
        </Flex>

        <Flex
          mx={["7%", "0"]}
          mt={["74px", "64px"]}
          pb="32px"
          justifyContent={"center"}
          flexDir={["column", "row", "row", "row", "row"]}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Button
            w={["100%", "auto"]}
            hoverColor="gray.gray600"
            background="green"
            mr={["0", "32px", "32px", "32px", "32px"]}
            onClick={() => nav("/login")}
          >
            Enroll now
          </Button>
          <Button
            w={["100%", "auto"]}
            mt={["10px", "0"]}
            hoverColor="gray.gray600"
            border="1px solid green"
            background="green"
            onClick={() => nav("/register")}
          >
            Sign up
          </Button>
        </Flex>
      </Box>
      {/* product */}
      {/* <Box minH={"100vh"} mt="32px" px="3%" id="product">
        <HeaderTitle title={"e-consular product"} mt={"32px"} />
        <Flex justifyContent={"apace-between"} flexWrap={"wrap"} mt="62px">
          <Box bg="#fff" w={["100%", "100%", "100%", "48%", "48%",]} boxShadow={"4px 6px 12px 3px #B5B5B5 "} p="10px">
            <Text fontSize={["18px", "25px", "25px", "30px", "30px"]} lineHeight={["20px", "32px", "35px", "40px", "35px"]} color="blue" textTransform={"capitalize"} fontWeight="700">Dannon's consular card system. Real-time Application & Quick Ticketing
            </Text>
            <Text mt="22px" fontSize={["18px", "25px", "25px", "30px", "30px"]} lineHeight={["20px", "32px", "35px", "40px", "35px"]} textTransform={"capitalize"} color="green" fontWeight="700">Our automated technology now allows you to process your consular card in real time</Text>
            <Flex justifyContent={"center"} mt="12px">
              <Button w='auto' onClick={() => nav("/login")}> Start now</Button>
            </Flex>
          </Box>
          <Box w={["100%", "100%", "100%", "48%", "48%",]}>
            <Image src={productImg} />
          </Box>
        </Flex>
      </Box> */}

      {/* why us */}

      {/* <div id="why-us">
        <Box minH={"100vh"} mt="32px" px="3%" >
          <HeaderTitle title={"our benefits"} mt={"32px"} />
          <Flex justifyContent={"space-between"} flexWrap={"wrap"} mt="62px">
            <WhyChooseUsCard
              title={"Easy to use"}
              content={`With the new automated system, Nigerians living in diaspora can now process there consular card with ease`}
              icon={<AiFillCheckSquare />}
              color='#242424'
            />
            <WhyChooseUsCard
              title={"Fast tracking"}
              content={`Processing your card has never being easy like this before, you can book an appointment with just a few clicks`}
              icon={<AiFillThunderbolt />}
              color='#8B1071'
            />
            <WhyChooseUsCard
              title={"Attitude"}
              content={`With the Nigerian Consular Card in your pocket, you're not alone`}
              icon={<AiFillCheckSquare />}
              color='#261191'
            />
            <WhyChooseUsCard
              title={"24 hours support"}
              content={`Staying connected is critical when you're apart from family. The Consular Card helps to bridge the gap, 
                keeping you connected at all times. `}
              icon={<BsFillChatTextFill />}
              color='#540731'
            />
            <WhyChooseUsCard
              title={"We care"}
              content={`The Nigerian Consular Card is more than simply an identification card in the intricate fabric of the diaspora experience;
                 it's a symbol of compassion that goes across oceans.`}
              icon={<FaBaby />}
              color='#9C310D'
            />
            <WhyChooseUsCard
              title={"Our experience"}
              content={`Our experiences and journeys are enriched by having the Nigerian Consular Card in our possession. It's more than simply a card; it serves as a symbol of our common
                 history and the boundless potential that Nigerians living abroad possess.`}
              icon={<BsFillExplicitFill />}
              color='#0B901B'
            />
          </Flex>
        </Box>
      </div> */}
      {/* contact us */}

      <div id="contact">
        <Box minH={"100vh"} mt="32px" px="3%">
          <HeaderTitle title={"contact us"} mt={"32px"} />

          <Flex justifyContent={"space-between"} mt="62px" flexWrap={"wrap"}>
            <Box
              w={["100%", "100%", "100%", "48%", "48%"]}
              display={"flex"}
              alignItems={"center"}
            >
              <Image w="100%" src={contactImg} />
            </Box>
            <Box w={["100%", "100%", "100%", "48%", "48%"]}>
              <Text
                textAlign={"center"}
                fontWeight={"700"}
                fontSize={"30px"}
                color="blue.blue500"
              >
                Have a Question, Contact us!
              </Text>
              <Text
                textAlign={"center"}
                fontWeight={"500"}
                fontSize={"20px"}
                color="blue.blue500"
              >
                Would be glad to hear from you!
              </Text>

              {ShowMsg ? (
                <Text bg="green" color="#fff" px="10px" py="15px" mt="32px">
                  Your response has been capture and a message will be sent to
                  your mail shortly.
                </Text>
              ) : (
                <Stack mt={"32px"} spacing={"22px"} mb="32px">
                  <Input
                    value={Payload.name}
                    val={Payload.name !== "" ? true : false}
                    type="text"
                    leftIcon={<FaUser />}
                    label="Full Name"
                    id="name"
                    onChange={handlePayload}
                  />
                  <Input
                    value={Payload.email}
                    val={Payload.email !== "" ? true : false}
                    type="email"
                    leftIcon={<MdEmail />}
                    label="Email"
                    id="email"
                    onChange={handlePayload}
                  />
                  <Input
                    value={Payload.subject}
                    val={Payload.subject !== "" ? true : false}
                    type="text"
                    leftIcon={<MdSubject />}
                    label="Subject"
                    id="subject"
                    onChange={handlePayload}
                  />

                  <Textarea
                    _placeholder={{ color: "#242424" }}
                    value={Payload.message}
                    val={Payload.message !== "" ? true : false}
                    type="text"
                    placeholder="Enter message"
                    borderColor={"#242424"}
                    id="message"
                    onChange={handlePayload}
                  ></Textarea>

                  <Button onClick={sendMessage} isLoading={Loading}>
                    Submit
                  </Button>
                </Stack>
              )}
            </Box>
          </Flex>
        </Box>
      </div>
    </OuterLayout>
  );
}
