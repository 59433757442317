import React from "react"

function formatDate(dateString) {
  if (!dateString) return "";

  const date = new Date(dateString);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return date.toLocaleString(undefined, options);
}

export default formatDate