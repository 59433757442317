import React, { useState } from 'react'
import DashboardLayout from '../Layout'
import Seo from '../Utils/Seo'
import Headers from '../Components/Header'
import { Box, Flex, Select, Stack, Table, Tbody, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import Input from '../Components/Input'
import Button from '../Components/Button'
import { useEffect } from 'react'
import { CandidateProfile, GetCountries, GetReservationAPI } from '../Utils/ApiCalls'
import { toast } from 'react-toastify'
import TableRow from '../Components/TableRow'
import { PiPlus } from 'react-icons/pi'
import { AiOutlinePlus } from 'react-icons/ai'
import CreateReservation from '../Components/ModalComponent/CreateReservation'
import moment from 'moment/moment'
import Preloader from '../Components/Preloader'




const MakeReservation = () => {

  // var base64Img = require('base64-img');

  const profileDetails = JSON.parse(sessionStorage.getItem("profileDetails"))?.profile_status
  
const [ProfileStatus, setProfileStatus] = useState(profileDetails);
  

  const [Payload, setPayload] = useState({

    title: "",
    first_name: "",
    last_name: "",
    gender: "",
    dob: "",
    country_birth: "",
    city_birth: "",
    community: "",
    maiden_name: "",
    education: "",
    marital_status: "",
    no_children: "",
    profile_photo: "",
    passport_no: "",
    passport_date: "",
    nin: "",
    phone: "",
    whatsapp_no: "",
    address_nigeria: "",
    current_location: "",
    kin_name: "",
    kin_address: "",
    kin_phone: "",
    kin_email: "",
    relationship: "",
    signature: ""



  });

  const [Countries, setCountries] = useState([]);
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);


  const handlePayload = (e) => {
    setPayload({ ...Payload, [e.target.id]: e.target.value })

  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSignature = (e) => {
    let file = e.target.files[0];
    
    // var reader = new FileReader();
    
    // base64('path/demo.png', function(err, data) {})

    setPayload((prevState) => ({ ...prevState, [e.target.id]: file.name }))

  console.log("file", file)
    // if (!file) {
    //   return;
    // };
    // // console.log('reader',reader,'file',file )
    // reader.onloadend = () => {
    //   setPayload((prevState) => ({ ...prevState, [e.target.id]: reader.result }))
    // }
    // reader.readAsDataURL(file)



}


const [base64Image, setBase64Image] = useState('');

const [Signature, setSignature] = useState("");
const [IsLoading, setIsLoading] = useState(true);




  
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSignature(file.name)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setBase64Image(base64Data);
        setPayload((prevState) => ({ ...prevState, signature: base64Data }))
      };
      reader.readAsDataURL(file);


    }
  }

console.log("base64Image", Signature, Payload)


const SubmitProfile = async (e) => {
  e.preventDefault()
  setLoading(true)
  try {
    const result = await CandidateProfile(Payload);
    console.log("CandidateProfile", result);
    if (result.status === 200) {

      setLoading(false)
      toast.success("Profile Submitted successful!");

      setPayload({

        title: "",
        first_name: "",
        last_name: "",
        gender: "",
        dob: "",
        country_birth: "",
        city_birth: "",
        community: "",
        maiden_name: "",
        education: "",
        marital_status: "",
        no_children: "",
        profile_photo: "",
        passport_no: "",
        passport_date: "",
        nin: "",
        phone: "",
        whatsapp_no: "",
        address_nigeria: "",
        current_location: "",
        kin_name: "",
        kin_address: "",
        kin_phone: "",
        kin_email: "",
        relationship: "",
        height: "",
        signature: ""
      })


    } else {
      setLoading(false)
      
    }
  } catch (error) {
    setLoading(false)

    toast.error(error.message);
  }

}



const getCountries = async () => {

  try {
    const result = await GetCountries();

    if (result.status === 200) {
      setCountries(result.data.data)
    } else {

    }
  } catch (error) {

    // toast.error(error.message);
  }
}
const getReservations = async () => {

  try {
    const result = await GetReservationAPI();
    console.log("reservation", result)

    if (result.status === 200) {
      setData(result.data.detail)
      setIsLoading(false)
    } else {

    }
  } catch (error) {

    // toast.error(error.message);
  }
}


useEffect(() => {
  getCountries()
  getReservations()
}, [isOpen]);

return (

  <DashboardLayout>

    <Seo title="Make Reservation" description="Card empirion Reservation" />

{
  ProfileStatus == false ? (
    <Box mt="12px" px="15px" py="15px" bg={"#fff"} rounded="8px" mb="32px">
    <Headers text={"Complete the information to apply for your card"} mt="12px" />
      <form onSubmit={SubmitProfile}>

        <Flex justifyContent={"space-between"} mt="12px" flexWrap={"wrap"}>

          <Box w={["100%", "100%", "48%", "48%", "48%"]}>

            <Stack mt="12px" spacing="15px" >
              <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select Title' fontWeight={"400"} fontSize={Payload.title != "" ? "16px" : "12px"} id='title' value={Payload.title} onChange={handlePayload}>
                <option value={`mr`}>mr</option>
                <option value={`mrs`}>mrs</option>
                <option value={`miss`}>miss</option>
              </Select>

              <Input label='First name' id='first_name' type='text' value={Payload.first_name} val={Payload.first_name !== "" ? true : false} onChange={handlePayload} />
              <Input label='Last name' id='last_name' type='text' value={Payload.last_name} val={Payload.last_name !== "" ? true : false} onChange={handlePayload} />

              <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select Gender' fontWeight={"400"} fontSize={Payload.gender != "" ? "16px" : "12px"} id='gender' value={Payload.gender} onChange={handlePayload}>
                <option value={`male`}>male</option>
                <option value={`female`}>female</option>

              </Select>


              <Input label='Date of birth' id='dob' type='date' value={Payload.dob} val={Payload.dob !== "" ? true : false} onChange={handlePayload} />

              <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select country of birth' fontWeight={"400"} fontSize={Payload.country_birth != "" ? "16px" : "12px"} id='country_birth' value={Payload.country_birth} onChange={handlePayload}>
                {
                  Countries?.map((item, i) => (

                    <option key={i} value={`${item.country}`}>{item.country}</option>

                  ))
                }


              </Select>
              <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select city of birth' fontWeight={"400"} fontSize={Payload.city_birth !== "" ? "16px" : "12px"} id='city_birth' value={Payload.city_birth} onChange={handlePayload}>
                {
                  Countries?.filter(item => item.country === Payload.country_birth)[0]?.cities.map((item, i) => (


                    <option key={i} value={`${item}`}>{item}</option>


                  ))
                }

              </Select>

              <Input label='Community' id='community' type='text' value={Payload.community} val={Payload.community !== "" ? true : false} onChange={handlePayload} />
              <Input label='Maiden Name' id='maiden_name' type='text' value={Payload.maiden_name} val={Payload.maiden_name !== "" ? true : false} onChange={handlePayload} />
              <Input label='Educational Qualification' id='education' type='text' value={Payload.education} val={Payload.education !== "" ? true : false} onChange={handlePayload} />
              <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select Marital Status' fontWeight={"400"} fontSize={Payload.marital_status !== "" ? "16px" : "12px"} id='marital_status' value={Payload.marital_status} onChange={handlePayload}>
                <option value={`single`}>single</option>
                <option value={`married`}>married</option>
                <option value={`divorce`}>divorce</option>
                <option value={`widowed`}>widowed</option>
                <option value={`complicated`}>complicated</option>

              </Select>

              <Input label='No of Children' id='no_children' type='number' value={Payload.no_children} val={Payload.no_children !== "" ? true : false} onChange={handlePayload} />
              <Input label='Heights (cm)' id='height' type='text' value={Payload.height} val={Payload.height !== "" ? true : false} onChange={handlePayload} />


            </Stack>

          </Box>
          <Box w={["100%", "100%", "48%", "48%", "48%"]}>

            <Stack mt="12px" spacing="15px">

              <Input label='Passport No' id='passport_no' type='text' value={Payload.passport_no} val={Payload.passport_no !== "" ? true : false} onChange={handlePayload} />

              <Input label='Passport Exp Date' id='passport_date' type='date' value={Payload.passport_date} val={Payload.passport_date !== "" ? true : false} onChange={handlePayload} />
              <Input label='National Identity No' id='nin' type='number' value={Payload.nin} val={Payload.nin !== "" ? true : false} onChange={handlePayload} />
              <Input label='Phone No' id='phone' type='number' value={Payload.phone} val={Payload.phone !== "" ? true : false} onChange={handlePayload} />
              <Input label='Whats App No' id='whatsapp_no' type='number' value={Payload.whatsapp_no} val={Payload.whatsapp_no !== "" ? true : false} onChange={handlePayload} />
              <Input label='Nigeria address ' id='address_nigeria' type='text' value={Payload.address_nigeria} val={Payload.address_nigeria !== "" ? true : false} onChange={handlePayload} />
              <Input label='Current Location ' id='current_location' type='text' value={Payload.current_location} val={Payload.current_location !== "" ? true : false} onChange={handlePayload} />
              <Input label='Kin name ' id='kin_name' type='text' value={Payload.kin_name} val={Payload.kin_name !== "" ? true : false} onChange={handlePayload} />
              <Input label='Kin address ' id='kin_address' type='text' value={Payload.kin_address} val={Payload.kin_address !== "" ? true : false} onChange={handlePayload} />
              <Input label='Kin phone ' id='kin_phone' type='number' value={Payload.kin_phone} val={Payload.kin_phone !== "" ? true : false} onChange={handlePayload} />
              <Input label='Kin email ' id='kin_email' type='email' value={Payload.kin_email} val={Payload.kin_email !== "" ? true : false} onChange={handlePayload} />
              <Input label='relationship ' id='relationship' type='text' value={Payload.relationship} val={Payload.relationship !== "" ? true : false} onChange={handlePayload} />
              <Input label='Upload signature ' id='signature' type='file'  onChange={handleImageUpload} />

            </Stack>

          </Box>


        </Flex>

        <Button isLoading={Loading} isSubmit={true} mt={"32px"}>Submit</Button>
      </form>
    </Box>
  ):(
    <Box mt="12px" px="15px" py="15px" bg={"#fff"} rounded="8px" mb="32px">
    <Headers text={"Make reservation"} mt="12px" />

      {
        IsLoading && (

          <Preloader/>
        )
      }

    <Box overflowX="auto">
                <Flex justifyContent={"flex-end"}><Button  px='15px' w="auto" onClick={()=>onOpen()} mt="10px" size='md'><AiOutlinePlus/></Button></Flex>
                <Table variant='striped' maxWidth="100%" overflowX={"auto"} mt="10px"  whiteSpace="nowrap">
                        <Thead>
                            <Tr bg={"gray.gray600"} ml="0">
                                <Th  color="#fff" fontSize={"10px"}>S/N</Th>
                                <Th  color="#fff" fontSize={"10px"}>Reservation No</Th>
                                <Th  color="#fff" fontSize={"10px"}>Embassy</Th>
                                <Th  color="#fff" fontSize={"10px"}>status</Th>
                                <Th  color="#fff" fontSize={"10px"}>Created</Th>
                                
                            </Tr>
                        </Thead>
                        <Tbody pl="10px important">
                        {
                          Data?.map((item,i)=>(
                            <TableRow
                                type="make reservation"
                                key={i}
                                sn={i+1}
                                reservationNo={item.reservation_number}
                                embassy={`${item.embassy_data?.address}, ${item.embassy_data?.country}`}
                                status={item.status}
                                created={moment(`${item.created_at}`).format("LLL")}

                               
                               
                            />
                          ))
                        }
                            
                            
         
                           
                         
                        </Tbody>
                       
                    </Table>
                </Box>

             
    </Box>
  ) 
}

   
<CreateReservation isOpen={isOpen} onClose={onClose}/>
  </DashboardLayout>
)
}

export default MakeReservation