import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Button from '../Button'
import { ApproveReservationAPI, CreateReservationAPI, GetEmbassy } from '../../Utils/ApiCalls';
import { toast } from 'react-toastify';
import Input from '../Input';


export default function ApproveReservation({ isOpen, onClose, singleDetails }) {

    const [Payload, setPayload] = useState({
        date: "",
        time: "",
        status: "",
        details: ""
    });

    const [Loading, setLoading] = useState(false);

    // console.log("singleDetails", singleDetails)

    const handlePayload = (e) => {
        setPayload({
            ...Payload, [e.target.id]: e.target.value
        })
    }

    const proceed = async () => {

        setLoading(true)
        try {

            const result = await ApproveReservationAPI(Payload, singleDetails?.id);
            console.log("ApproveReservation", result);
            setLoading(false)
            if (result.status === 200) {

                toast.success("Response has been submitted successfully");

                onClose()
                setPayload({
                    date: "",
                    time: "",
                    status: "",
                    details: ""
                })

            } else {

            }
        } catch (error) {
            setLoading(false)

            toast.error(error.message);
        }


    }




    useEffect(() => {

    }, [isOpen]);


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered  size={"lg"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Approve reservation</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Box>

                        <Stack>
                            <Input id="date" label="Date" type="date" value={Payload.date} onChange={handlePayload} />
                            <Input id="time" label="time" type="time" value={Payload.time} onChange={handlePayload} />
                            <Input id="details" label="Details/reason" type="text" value={Payload.details} onChange={handlePayload} />
                            <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select Approval Status' fontWeight={"400"} fontSize={Payload.status !== "" ? "16px" : "12px"} id='status' value={Payload.status} onChange={handlePayload}>
                                <option value={`approved`}>Approved</option>
                                <option value={`rejected`}>Rejected</option>

                            </Select>

                        </Stack>





                    </Box>

                </ModalBody>

                <ModalFooter>
                    <Button  isLoading={Loading} onClick={() => proceed("approve")}>
                        Submit
                    </Button>
                 
                </ModalFooter>
            </ModalContent>

        </Modal>
    )
}
