import React, { useState } from "react";
import { MdDelete, MdOutlinePending } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import formatDate from "../../Components/FornatDate";
import { Avatar, Box, IconButton, Text } from "@chakra-ui/react";

function MessageItem({
  messages,
  message,
  setMessageToDelete,
  setFormTitle,
  setMsg,
  setIsDeleteModalOpen,
  index,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const OnlineUSerDetails = JSON.parse(localStorage.getItem("DannonUser"));

  const EditBtn = (message) => {
    setFormTitle("Edit Message");
    setMsg(message);
  };

  const handleDeleteClick = (message) => {
    setMessageToDelete(message);
    setIsDeleteModalOpen(true);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box
      display="flex"
      gap="10px"
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // borderBottom={
      //   index !== messages.length - 1 ? "1px solid #e2e8f0" : "none"
      // }
      paddingX="10px"
      paddingY="20px"
      // boxShadow={index !== messages.length - 1 ? "sm" : "none"}
    >
      <Avatar
        // name={`${OnlineUSerDetails?.first_name} ${OnlineUSerDetails?.last_name}`}
        name="S"
      />
      <Box display="flex" flexDirection="column" mt="8px" w="100%">
        <Box mb="10px">
          <Box
            display="flex"
            flexDirection="column"
            gap="5px"
            lineHeight="25px"
          >
            <Text color="gray" fontSize={{ base: "12px", md: "14px" }}>
              {/* {`${OnlineUSerDetails?.first_name} ${OnlineUSerDetails?.last_name}`} */}
              Solomon Adeleke
            </Text>
          </Box>
          <Text fontWeight="500">
            {/* {message?.subject} */}
            Delay
            </Text>
          <Text color="gray" fontSize={{ base: "12px", md: "14px" }}>
            {/* {message?.message} */}
            Help me out with the payment issues
          </Text>
        </Box>
        <button 
        // onClick={() => EditBtn(message)}
        >
          <FaEdit size={15} color="blue" />
        </button>
        {isHovered && (
          <IconButton
            onClick={() => handleDeleteClick(message)}
            icon={<MdDelete />}
            size="sm"
            position="absolute"
            top="50%"
            right="5px"
            transform="translateY(-50%)"
            color="red"
          />
        )}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box display="flex" gap="5px">
          <MdOutlinePending size={20} color="blue" />
          <Text color="blue" fontSize={{ base: "12px", md: "14px" }}>
            {/* {message?.status} */}
            pending
          </Text>
        </Box>

        <Text
          color="gray"
          textAlign="right"
          fontSize={{ base: "10px", md: "8px" }}
          mt="15px"
        >
          {/* {formatDate(message?.created_at)} */}
          12/12/2021
        </Text>
      </Box>
    </Box>
  );
}

export default MessageItem;
