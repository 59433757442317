import React, { useState } from "react";
import { verifyOtp } from "../Utils/ApiCalls";
import { toast } from "react-toastify";

const OtpPage = ({ email }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    setOtp([...otp.map((d, i) => (i === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Combine OTP array into single string
      const otpValue = otp.join("");

      // POST request to the API endpoint with OTP
      const result = await verifyOtp(otpValue);

      // Handle successful response
      setSuccessMessage(
        "OTP verification successful! Redirecting to dashboard..."
      );
      setTimeout(() => {
        // Simulate a redirect to the dashboard after 2 seconds
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      // Handle error with toast notification
      if (!error?.response) {
        toast.error("Invalid OTP");
      } else if (error.response?.status === 400) {
        toast.error("OTP Not Found");
      } else if (error.response?.status === 401) {
        toast.error("Invalid OTP");
      } else {
        toast.error("OTP Verification Failed");
      }
    }
  };

  return (
    <div className="w-full h-[70vh] flex items-center justify-center flex-col">
      <h1 className="font-bold text-2xl mb-6 text-center">Enter OTP</h1>
      <p>An OTP has been sent to your email for verification</p>
      {errorMessage && (
        <p className="text-red-500 py-2 px-20 flex flex-row items-center justify-center text-[15px] gap-2">
          Error: {errorMessage}
        </p>
      )}
      {successMessage && (
        <p className="text-green-500 py-2 px-20 flex flex-row items-center justify-center text-[15px] gap-2">
          {successMessage}
        </p>
      )}
      <form
        onSubmit={handleSubmit}
        className="flex justify-center items-center"
      >
        <div className="flex justify-between sm:gap-6 gap-3 mt-5">
          {otp.map((data, index) => {
            return (
              <input
                className="shadow appearance-none border rounded w-10 sm:w-16 text-gray-700 py-2 px-3 text-center leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                maxLength="1"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
              />
            );
          })}
        </div>
        <input
          type="submit"
          value="Verify OTP"
          className="w-40 h-10 bg-blue-600 border border-gray-300 rounded-lg px-4 py-2 text-white cursor-pointer absolute bottom-5"/>
      </form>
    </div>
  );
};

export default OtpPage;
