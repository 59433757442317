import React, { useState } from "react";
import { userLogout } from "../Utils/ApiCalls";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { RiArrowDownSLine } from "react-icons/ri";
import { AiOutlineUser } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { HiOutlineUserCircle } from "react-icons/hi";
import { IoMdNotificationsOutline } from "react-icons/io";
import Input from "./Input";

const ProfileCard = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await userLogout();
      setShowLogoutModal(false);
      sessionStorage.removeItem("profileDetails");
      sessionStorage.removeItem("UserDetails");
      window.location.href = "/";
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  // console.log("loggedInUser",loggedInUser)



    const loggedInUser = JSON.parse(sessionStorage.getItem("profileDetails"));
  const profile = JSON.parse(sessionStorage.getItem("profileDetails"))?.image_data?.image



  // console.log("loggedInUser", loggedInUser)



  const nav = useNavigate()

  return (

    <>
      <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton isActive={isOpen} as={Button} rightIcon={<RiArrowDownSLine />} bg="transparent" mt="3px" _hover={{ bg: "transparent" }} _active={{ bg: "transparent" }}>
          <HStack>
          {loggedInUser?.profile_data ? (
          <Avatar size={["sm","sm","md","md","md"]}   name={`${loggedInUser?.profile_data?.first_name||" "} ${loggedInUser?.profile_data?.last_name||" "}`} src={`${profile}`} />

          ):(

          <Avatar size={["sm","sm","md","md","md"]}  src='https://bit.ly/broken-link' />

          ) }
          <Box textAlign={"left"} display={["none","none","block","block","block",]}>
            <Text fontWeight="bold" textTransform={"capitalize"} fontSize={{ base: '10px', md:'14px'}}>{`${loggedInUser?.profile_data?.first_name||" "} ${loggedInUser?.profile_data?.last_name||" "}`}</Text>
            <Text fontWeight="400" color="navy" textTransform={"capitalize"} fontSize={{ base: '10px', md:'14px'}}>{loggedInUser?.role||" "}</Text>
          </Box>
          </HStack>
          </MenuButton>
          <MenuList>

          <MenuItem>
          <Box textAlign={"left"} display={["block","block","none","none","none",]}>
            <Text fontWeight="bold" textTransform={"capitalize"} fontSize={{ base: '10px', md:'14px'}}>{`${loggedInUser?.profile_data?.first_name||" "} ${loggedInUser?.profile_data?.last_name||" "}`}</Text>
            <Text fontWeight="400" color="navy" textTransform={"capitalize"} fontSize={{ base: '10px', md:'14px'}}>{loggedInUser?.role||" "}</Text>
          </Box>
            </MenuItem>
          <MenuItem onClick={()=>onOpen()}>
              <HStack>
                <Box><IoMdNotificationsOutline /></Box>
                <Text fontSize={"13px"} fontWeight={"500"}>Notifications</Text>
                <Spacer/>
                <Box w="20px"  h="20px" rounded={"100px"} display={"flex"}  alignItems={"center"} fontSize={"10px"} fontWeight={"700"} justifyContent={"center"} bg="red" color="#fff">14</Box>
              </HStack>

            </MenuItem>
            <MenuItem onClick={()=>nav("/profile")}>
              <HStack>
                <Box><HiOutlineUserCircle /></Box>
                <Text fontSize={"13px"} fontWeight={"500"}>Profile</Text>
              </HStack>

            </MenuItem>
            
            <MenuItem onClick={handleLogoutConfirm}>
              <HStack>
                <Box><BiLogOut /></Box>
                <Text fontSize={"13px"} fontWeight={"500"}>Logout</Text>
              </HStack>
            </MenuItem>
          </MenuList>


        </>
      )}


     
    </Menu>

     <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
   
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Notification</DrawerHeader>

          <DrawerBody>
          </DrawerBody>

          <DrawerFooter>
    
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ProfileCard;
