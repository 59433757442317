import {
  FormControl,
  FormLabel,
  Textarea as ChakraTextarea,
  FormHelperText,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";

export default function TextArea({
  id = "",
  val = false,
  label = "",
  bColor = "",
  isRequired = false,
  readOnly = false,
  helper = null,
  onChange = null,
  isDisabled = false,
  size = "lg",
  rows = 4,
  cols = 8,
  placeholder = `Enter ${label.toLowerCase()}`,
  py,
  rightIcon = null,
  w = "100%",
  borderColor = "#017CC2",
  labelBg = "#fff",
  leftIcon,
  iconColor = "#242424",
  rounded = "8px",
  borderBottom,
  fontSize = "20px",
  ...rest
}) {
  const [active, setActive] = useState(Boolean(rest.value));

  return (
    <FormControl
      id={id}
      isReadOnly={readOnly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      pos="relative"
    >
      <FormLabel
        pos="absolute"
        transform={`translateY(${
          active || val ? "-19px" : "7px"
        }) translateX(30px)`}
        bottom="3"
        zIndex="10"
        fontSize={active ? "xs" : "12px"}
        fontWeight="400"
        color="#000"
        bg={active ? labelBg : labelBg}
        px="4px"
      >
        {label}
      </FormLabel>

      <Box>
        <ChakraTextarea
          onChange={onChange}
          {...rest}
          placeholder={active || !label ? placeholder : ""}
          focusBorderColor={borderColor}
          _focus={{ borderColor: borderColor }}
          size={size}
          py={py}
          color="#000"
          fontWeight="400"
          fontSize={{ base: "12px", md: "16px" }}
          fontFamily="body"
          borderColor={active ? "#017CC2" : bColor}
          borderBottom={borderBottom}
          rounded={rounded}
          bg="transparent"
          w={w}
          onFocus={() => setActive(true)}
          onBlur={() => {
            if (!rest.value) {
              setActive(false);
            }
          }}
          rows={rows}
          cols={cols}
        />
        {rightIcon && (
          <Box pos="absolute" right="4" top="6">
            {rightIcon}
          </Box>
        )}
      </Box>
      {helper && (
        <FormHelperText pos="absolute" p={1} m="0" fontSize="10px">
          {helper}
        </FormHelperText>
      )}
    </FormControl>
  );
}
