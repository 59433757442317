import React from "react";
import Input from "./Input";
import { Flex, InputGroup, InputRightElement, Select } from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";

function SearchBarr({
  w,
  placeholder,
  onChange,
  value,
  rounded,
  mb,
  mt,
  rightIcon,
  bg,
  mr,
  roundedStart,
  roundedEnd,
}) {
  return (
    <Flex
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      flexDirection={{ base: "column", md: "row" }}
      w={["100%", "100%", "40%", "40%", "40%"]}
      mt={mt}
    >
      <InputGroup
        mt={["12px", "12px", "0", "0", "0"]}
        mb={mb}
        display="flex"
        alignItems="center"
      >
        <Input
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          leftIcon={<BiSearch />}
          rounded={rounded}
        />
        {rightIcon && (
          <InputRightElement
            mt={mt}
            roundedStart={roundedStart}
            roundedEnd={roundedEnd}
            bg={bg}
            children={rightIcon}
            mr={mr}
          />
        )}
      </InputGroup>
    </Flex>
  );
}

export default SearchBarr;
