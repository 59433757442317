import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import FAQuestions from "../Utils/data/FAQ.json";
import NavBar from "../OuterLayout/NavBar";
import Footer from "../OuterLayout/Footer";

export const FAQ = () => {
  const [isOpen, setIsOpen] = useState(FAQuestions);

  const handleToggle = (index) => {
    setIsOpen(
      isOpen.map((item, i) =>
        i === index
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  return (
    <>
      <NavBar />
      <div className="bg-gray px-4 md:px-10 py-7 md:py-10 mt-14">
        <div className="max-w-[1500px] m-auto flex flex-col">
          <div className="lg:flex lg:items-center lg:justify-center ">
            <h3 className="font-bold text-[16px] md:text-[18px] lg:text-[24px] leading-[25px] lg:leading-[60px] text-center text-[#245FAF]">
              Frequently Asked Questions
            </h3>
          </div>
          <div className="md:flex md:flex-col md:justify-center md:pb-10">
            {isOpen.map((item, index) => (
              <div key={index} className="cursor-pointer">
                <div
                  onClick={() => handleToggle(index)}
                  className="flex justify-between items-center  mt-8"
                >
                  <p className="text-[12px] md:text-[14px] lg:text-[18px] font-semibold">
                    {item.question}
                  </p>
                  {item.isOpen ? (
                    <IoIosArrowUp size={20} />
                  ) : (
                    <IoIosArrowDown size={20} />
                  )}
                </div>
                {item.isOpen && (
                  <p className="mt-5 text-[12px] md:text-[14px] lg:text-[18px]">
                    {item.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
