import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";

export default function FilterBtn({
  bgColor = "gray.gray600",
  setValue,
  text = "Filter user type",
  userType = "Filter user type",
  status = "Filter by Status",
}) {
  const [FilterValue, setFilterValue] = useState({
    status: "",
  });

  const handlePayload = (e) => {
    setFilterValue({ ...FilterValue, [e.target.id]: e.target.value });
  };

  return (
    <Box mt={["10px", "10px", "10px", "-7px", "-7px"]}>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Box}
              px="15px"
              py="10px"
              rounded={"8"}
              color="#fff"
              bg={bgColor}
              cursor={"pointer"}
            >
              {isOpen ? (
                <HStack>
                  <Box fontSize={"15px"}>
                    <FaFilter />
                  </Box>
                  <Text
                    textTransform={"capitalize"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                  >
                    {text}
                  </Text>
                  <Box fontSize={"15px"}>
                    <BsCaretUpFill />
                  </Box>
                </HStack>
              ) : (
                <HStack>
                  <Box fontSize={"15px"}>
                    <FaFilter />
                  </Box>
                  <Text
                    textTransform={"capitalize"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                  >
                    {userType}
                  </Text>
                  <Box fontSize={"15px"}>
                    <BsCaretDownFill />
                  </Box>
                </HStack>
              )}
            </MenuButton>
            <MenuList>
              <MenuItem color="#000" _hover={{ bg: bgColor, color: "#fff" }}>
                {/* <Select id='status' _focus={{ borderColor: "017CC2" }} placeholder='Filter by Status' borderColor={FilterValue.status !== "" ? "#017CC2" : "#242424"} fontSize={FilterValue.status ? "16px" : "12px"} fontWeight={"400"} value={FilterValue.status} size='lg' onChange={handlePayload}>
                            <option value="approve">approve</option>
                            <option value="pending">pendin</option>
                            <option value="reject">reject</option>
                           </Select> */}
                <Text
                  textTransform={"capitalize"}
                  fontSize={"15px"}
                  fontWeight={"500"}
                >
                  {status}
                </Text>
              </MenuItem>
              <MenuItem color="#000" _hover={{ bg: bgColor, color: "#fff" }}>
                <Text
                  textTransform={"capitalize"}
                  fontSize={"15px"}
                  fontWeight={"500"}
                >
                  approve
                </Text>
              </MenuItem>
              <MenuItem color="#000" _hover={{ bg: bgColor, color: "#fff" }}>
                <Text
                  textTransform={"capitalize"}
                  fontSize={"15px"}
                  fontWeight={"500"}
                >
                  pending
                </Text>
              </MenuItem>
              <MenuItem color="#000" _hover={{ bg: bgColor, color: "#fff" }}>
                <Text
                  textTransform={"capitalize"}
                  fontSize={"15px"}
                  fontWeight={"500"}
                >
                  reject
                </Text>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
}
