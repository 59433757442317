import React, { Children } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardLayout from "../Layout";
import Login from "../Pages/Login";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Register from "../Pages/Register";
import Dashboard from "../Pages/Dashboard";
// import UserList from "../Pages/UserList";
import AttestUser from "../Pages/AttestUser";
import OtpReg from "../Pages/OtpReg";
import MakeReservation from "../Pages/MakeReservation";
import UserprofileForm from "../Pages/UserprofileForm";
import Profile from "../Pages/Profile";
import AdminList from "../Pages/AdminList";
import ReservationList from "../Pages/ReservationList";
import NewReservation from "../Pages/NewReservation";
import EmbassyList from "../Pages/EmbassyList";
import ManageAgents from "../Pages/ManageAgents";
import ManageStaff from "../Pages/ManageStaff";
import ManageRoles from "../Pages/ManageRoles";
import CardPrinting from "../Pages/CardPrinting";
import UserList from "../Pages/UserList";
import Adduser from "../Components/AddUser";
import Updateuser from "../Components/UpdateUser";
import Home from "../Pages/Home/Home";
import ManageAdmins from "../Pages/ManageAdmins";
import BiometricsUpload from "../Pages/BiometricsUpload";
import UserSupport from "../Pages/User/UserSupport";
import { AdminSupport } from "../Pages/Admin/AdminSupport";
import { Terms } from "../Pages/Terms";
import { FAQ } from "../Pages/FAQ";

export default function Index() {
  return (
    <>
      <ToastContainer></ToastContainer>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/terms-and-conditions" element={<Terms />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/otpreg" element={<OtpReg />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route
            exact
            path="/dashboard/users/user-list"
            element={<UserList />}
          />
          <Route
            exact
            path="/dashboard/reservation/make-reservation"
            element={<MakeReservation />}
          />
          <Route
            exact
            path="/dashboard/users/attest-user"
            element={<AttestUser />}
          />
          <Route
            exact
            path="/dashboard/admin/admin-list"
            element={<AdminList />}
          />
          <Route
            exact
            path="/reservation/reservation-list"
            element={<ReservationList />}
          />
          <Route
            exact
            path="/reservation/new-reservation"
            element={<NewReservation />}
          />
          <Route exact path="/embassy/embassy-list" element={<EmbassyList />} />
          <Route
            exact
            path="/card-management/card-printing"
            element={<CardPrinting />}
          />
          <Route
            exact
            path="/biometerics/biometerics-upload"
            element={<BiometricsUpload />}
          />
          <Route
            exact
            path="/staffs-&-admins/manage-admins"
            element={<ManageAdmins />}
          />
          <Route
            exact
            path="/staffs-&-admins/manage-staffs"
            element={<ManageStaff />}
          />
          <Route
            exact
            path="/access-control/manage-roles"
            element={<ManageRoles />}
          />
          <Route exact path="/help-support" element={<UserSupport />} />
          <Route exact path="/admin-help-support" element={<AdminSupport />} />
          <Route exact path="*" element={<div>Page not Found!</div>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
