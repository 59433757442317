import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
// import { DeleteUserSupportApi } from "../../Utils/ApiCall";
// import { showToast } from "../../../Utils/ToastTools";
import Button from "../../Components/Button";
import MessageItem from "./MessageItem";

function Message({
  messages,
  setPayload,
  setFormTitle,
  setMsg,
  msg,
  UserSupportData,
}) {
  // const noResults = messages.length === 0;
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);

  // const handleConfirmDelete = async () => {
  //   if (messageToDelete) {
  //     setLoading(true);
  //     try {
  //       const result = await DeleteUserSupportApi(messageToDelete.id);
  //       console.log(result);
  //       if (result.status === 201) {
  //         showToast({
  //           type: "success",
  //           message: "Message deleted successfully",
  //         });
  //       }
  //       UserSupportData();
  //       setLoading(false);
  //     } catch (e) {
  //       setLoading(false);
  //       showToast({
  //         type: "error",
  //         message: e.message,
  //       });
  //       console.error("Error:", e.message);
  //     }
  //     setIsDeleteModalOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   if (msg) {
  //     setPayload({ ...msg });
  //   }
  // }, [msg]);

  return (
    <Box w={{ base: "100%", md: "40%" }}>
      {/* {noResults ? (
        <Text textAlign="center" mt="20px" color="black">
          No Message
        </Text>
      ) : ( */}
      {/* messages?.map((message, index) => { */}
      {/* return ( */}
      <MessageItem
        // key={index}
        // index={index}
        // message={message}
        setMessageToDelete={setMessageToDelete}
        messages={messages}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        setFormTitle={setFormTitle}
        setMsg={setMsg}
      />
      {/* ); */}
      {/* }) */}
      {/* )} */}

      <Modal
        isOpen={isDeleteModalOpen}
        // onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalBody>Are you sure you want to delete this message?</ModalBody>
          <ModalFooter display="flex" gap="10px">
            <Button
              isLoading={loading}
              // onClick={handleConfirmDelete}
              background="red"
              hoverColor="red"
            >
              Delete
            </Button>
            <Button
            // onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Message;
