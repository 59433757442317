import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { UserRegister } from "../Utils/ApiCalls";
import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import Logo from '../assest/logo.png'
import Input from "../Components/Input";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import Button from "../Components/Button";
import { useEffect } from "react";


const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [Loading, setLoading] = useState(false);


  const nav = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();

    // Simple client-side validation
    if (!isValidEmail(email)) {
      setErrorMessage("Invalid email address");
    } else if (password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long");
    } else {
      // Clear any previous error message
      setErrorMessage("");

      console.log("FirstRegister");
      try {
        const result = await UserRegister({ email, password });
        console.log("result", result);
        if (result.status === 200) {
          setResponseMessage("Sign up successful,!, Check your email for OTP");
          toast.success("Login successful!");
          nav("/otpreg");
        } else {
          //   setErrorMessage("Email verification failed.");
          toast.error("Login failed. Please check your credentials.");
        }
      } catch (error) {
        // setErrorMessage("An error occurred while logging in.");
        toast.error("Email already exist. Please Log in.");
      }
    }
  };

  const isValidEmail = (email) => {
    // Simple email validation using regex (you can use a more sophisticated regex if needed)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if(sessionStorage.getItem("UserDetails")){
      nav("/dashboard")
    }
  }, []);

  return (
    <Flex
    justifyContent={"center"}
    alignItems={"center"}
    bg={"gray.gray100"}
    minH={"100vh"}
    h={"100vh"}
  >

    <Box
      rounded={"8px"}
      boxShadow={"5px 5px 15px 4px #a9a9a9"}
      bg={"#fff"}
      w={["90%", "80%", "60%", "40%", "40%"]}
      px="2%"
      py="32px"
    >
      <Flex justifyContent={"center"} mt="px">
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          p="5px"
        
          rounded={"50%"}
          onClick={()=>nav("/")}
          cursor={"pointer"}
         
        >
          <Image src={Logo} w="40%" mt="-10px" alignItems={"center"} />
        </Box>
      </Flex>
      <Text fontSize={"32px"} mt="10px" textAlign={"center"}>
        Register
      </Text>
      <form onSubmit={submitHandler}>

        <Stack spacing={"20px"} mt="12px">
          <Input
            leftIcon={<MdEmail />}
            value={email}
            label="Email"
            type="email"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            val={email !== "" ? true : false}
          />


          <Input
            leftIcon={<RiLockPasswordFill />}
            value={password}
            label="Password"
            type="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            val={password !== "" ? true : false}
          />

          <Text fontWeight={"400"} fontSize={"16px"} textAlign={"center"} mt="45px">Already have an account? <Box as="span" cursor={"pointer"} color={"blue.blue500"} fontWeight={"700"} onClick={() => nav("/login")}>Sign in</Box></Text>

          <Button isSubmit={true} isLoading={Loading}>
            Register
          </Button>
        </Stack>


      </form>
    </Box>
  </Flex>
  );
};

export default Register;
