import { Avatar, Box, HStack, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import DashboardLayout from '../Layout'
import Seo from '../Utils/Seo'
import { isAdminUser, isAdminUsers, isStaff, isUser } from '../Auth'
import UserProfilePage from '../Components/UserProfilePage'
import AdminStaffProfile from '../Components/AdminStaffProfile'


const Profile = () => {

    // const profileDetails = JSON.parse(sessionStorage.getItem("profileDetails"))?.user?.profile_status
    return (


        <DashboardLayout>
            <Seo title='Profile' description='Card Emperium'/>


            {
                isUser() && (
                    
                    <UserProfilePage/>
                )
            }


            {
                isUser() === false && (
                    <AdminStaffProfile/>
                )
            }



         
        </DashboardLayout>
    )
}

export default Profile