import React, { useState } from "react";
import { Avatar, Box, Text, Flex, Select } from "@chakra-ui/react";
import Button from "../../Components/Button";
import TextArea from "../../Components/TextArea";
import Input from "../../Components/Input";
// import { showToast } from "../../../Utils/ToastTools";
// import {
//   CreateUserSupportApi,
//   UpdateUserSupportApi,
// } from "../../Utils/ApiCalls";

function Form({
  handlePayload,
  Payload,
  setPayload,
  formTitle,
  msg,
  setFormTitle,
  UserSupportData,
}) {
  // console.log(msg);
  const [loading, setLoading] = useState(false);
  const [font, setFont] = useState("Arial");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderlined, setIsUnderlined] = useState(false);

  const OnlineUSerDetails = JSON.parse(localStorage.getItem("DannonUser"));

  // const handleCreateMessage = async () => {
  //   setLoading(true);
  //   try {
  //     const result = await CreateUserSupportApi(Payload);
  //     if (result.status === 201) {
  //       showToast({
  //         type: "success",
  //         message: "Message sent successfully",
  //       });
  //       UserSupportData();
  //     }
  //   } catch (e) {
  //     showToast({
  //       type: "error",
  //       message: e.message,
  //     });
  //     console.error("Error:", e.message);
  //   } finally {
  //     setLoading(false);
  //     setPayload({
  //       subject: "",
  //       message: "",
  //     });
  //   }
  // };

  // const handleUpdateMessage = async () => {
  //   setLoading(true);
  //   try {
  //     const result = await UpdateUserSupportApi(msg.id, Payload);
  //     if (result.status === 200) {
  //       showToast({
  //         type: "success",
  //         message: "Message updated successfully",
  //       });
  //       UserSupportData();
  //     }
  //   } catch (e) {
  //     showToast({
  //       type: "error",
  //       message: e.message,
  //     });
  //     console.error("Error:", e.message);
  //   } finally {
  //     setLoading(false);
  //     setPayload({
  //       subject: "",
  //       message: "",
  //     });
  //   }
  // };

  const handleFontChange = (e) => {
    setFont(e.target.value);
  };

  const handleBoldToggle = () => {
    setIsBold(!isBold);
  };

  const handleItalicToggle = () => {
    setIsItalic(!isItalic);
  };

  const handleUnderlineToggle = () => {
    setIsUnderlined(!isUnderlined);
  };

  const textStyle = {
    fontFamily: font,
    fontWeight: isBold ? "bold" : "normal",
    fontStyle: isItalic ? "italic" : "normal",
    textDecoration: isUnderlined ? "underline" : "none",
  };

  const clearMsg = () => {
    setPayload({
      subject: "",
      message: "",
    });

    setFormTitle("new");
  };

  return (
    <Box
      w={{ base: "100%", md: "60%" }}
      borderLeft={{ base: "none", md: "1px solid #e2e8f0" }}
      padding={{ base: "15px", md: "15px", lg: "15px" }}
    >
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" justifyContent="space-between" mt="10px" w="100%">
            <Box display="flex" gap="10px" alignItems="center">
              <Avatar
                // name={`${OnlineUSerDetails?.first_name} ${OnlineUSerDetails?.last_name}`}
                name="SA"
              />
              <Box display="flex" flexDirection="column" w="100%">
                <Box display="flex" gap="5px">
                  <Text fontSize={{ base: "12px", md: "14px" }}>
                    {/* {`${OnlineUSerDetails?.first_name} ${OnlineUSerDetails?.last_name}`} */}
                    Solomon Adeleke
                  </Text>
                </Box>
                <Text color="blue" fontSize={{ base: "10px", md: "12px" }}>
                  {/* {OnlineUSerDetails.email} */}
                  solomon@gmail.com
                </Text>
              </Box>
            </Box>

            {formTitle !== "new" && (
              <button
                style={{ color: "red", fontWeight: "bold" }}
                onClick={clearMsg}
              >
                Discard
              </button>
            )}
          </Box>
        </Box>
        <Box mt="30px">
          <Input
            rounded="5px"
            mt="8px"
            bColor="#e2e8f0"
            fontSize={{ base: "12px", md: "16px" }}
            label="Subject"
            id="subject"
            type="text"
            value={Payload.subject}
            val={Payload.subject !== "" ? true : false}
            onChange={handlePayload}
          />
        </Box>
        <Box mt="30px">
          <Flex
            gap="20px"
            alignItems="center"
            fontSize={{ base: "12px", md: "16px" }}
          >
            <Select
              cursor="pointer"
              w="100px"
              value={font}
              onChange={handleFontChange}
              fontSize={{ base: "12px", md: "16px" }}
            >
              <option value="Arial">Arial</option>
              <option value="Verdana">Verdana</option>
              <option value="Times New Roman">Times New Roman</option>
            </Select>
            <Text cursor="pointer" fontWeight="600" onClick={handleBoldToggle}>
              B
            </Text>
            <Text
              cursor="pointer"
              transform="skewX(-15deg)"
              fontWeight="600"
              onClick={handleItalicToggle}
            >
              I
            </Text>
            <Text
              cursor="pointer"
              fontWeight="600"
              onClick={handleUnderlineToggle}
            >
              <u>U</u>
            </Text>
          </Flex>
          <TextArea
            font={textStyle}
            rows={6}
            mt="10px"
            height="250px"
            placeholder="Write your message"
            id="message"
            type="text"
            value={Payload.message}
            val={Payload.message !== "" ? true : false}
            onChange={handlePayload}
          />
          <Button
            mt="10px"
            fontSize={{ base: "12px", md: "16px" }}
            // onClick={
            //   formTitle === "new" ? handleCreateMessage : handleUpdateMessage
            // }
            isLoading={loading}
          >
            {/* {formTitle === "new" ? "Send Message" : "Update Message"} */}
            Submit
          </Button>
        </Box>
      </>
    </Box>
  );
}

export default Form;
