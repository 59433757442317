import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { AdminStaffProfile, SignInApi, UserProfile } from '../Utils/ApiCalls';
import { Link, useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Logo from '../assest/logo.png'
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import Input from '../Components/Input';
import { MdEmail } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import Button from '../Components/Button';
import { useEffect } from 'react';

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [Loading, setLoading] = useState(false);

  const nav = useNavigate()


  const navigate = useNavigate();


  const getUSerDetails = async (tokenKey) => {

    try {
      const result = await UserProfile(tokenKey);


      console.log("userProfile", result)

      

      if (result.status === 200) {



        sessionStorage.setItem("profileDetails", JSON.stringify(result.data))

        setTimeout(() => {
          setLoading(false);
          toast.success("Login successful!");
          navigate("/dashboard");
          window.location.reload();
        }, 2000);
      } else {

      }
    } catch (error) {

      toast.error(error.message);
    }
  }
  

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setLoading(true);

    const loginData = {
      email,
      password,
    };

    try {
      const result = await SignInApi(loginData);
      console.log("result", result);
      if (result.status === 200) {
        sessionStorage.setItem(
          "UserDetails",
          JSON.stringify(result.data.data)
        );
        setErrorMessage("");

        getUSerDetails(result.data.data.access)
       


      } else {
        setLoading(false);
        toast.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };




  const validate = () => {
    if (email.trim() === "") {
      toast.warning("Please enter your email");
      return false;
    }
    if (password === "") {
      toast.warning("Please enter your password");
      return false;
    }
    return true;
  };

  
  useEffect(() => {
    if(sessionStorage.getItem("UserDetails")){
      nav("/dashboard")
    }
  }, []);
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      bg={"gray.gray100"}
      minH={"100vh"}
      h={"100vh"}
    >

      <Box
        rounded={"8px"}
        boxShadow={"5px 5px 15px 4px #a9a9a9"}
        bg={"#fff"}
        w={["90%", "80%", "60%", "40%", "40%"]}
        px="2%"
        py="32px"
      >
        <Flex justifyContent={"center"} mt="px">
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            p="5px"
          
            rounded={"50%"}
            onClick={()=>nav("/")}
            cursor={"pointer"}
           
          >
            <Image src={Logo} w="40%" mt="-10px" alignItems={"center"} />
          </Box>
        </Flex>
        <Text fontSize={"32px"} mt="10px" textAlign={"center"}>
          Login
        </Text>
        <form onSubmit={submitHandler}>

          <Stack spacing={"20px"} mt="12px">
            <Input
              leftIcon={<MdEmail />}
              value={email}
              label="Email"
              type="email"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              val={email !== "" ? true : false}
            />


            <Input
              leftIcon={<RiLockPasswordFill />}
              value={password}
              label="Password"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              val={password !== "" ? true : false}
            />

            <Text fontWeight={"400"} fontSize={"16px"} textAlign={"center"} mt="45px">Don't have an account? <Box as="span" cursor={"pointer"} color={"blue.blue500"} fontWeight={"700"} onClick={() => nav("/register")}>Sign up</Box></Text>

            <Button isSubmit={true} isLoading={Loading}>
              Login
            </Button>
          </Stack>


        </form>
      </Box>
    </Flex>
  )
}

export default Login