import axios from "axios";
import { MomoBaseURL, baseURL, token } from "./ApiConfig";

// Function to request user email verification
export const verifyOtp = (otp) => {
  const data = {
    otp,
  };

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/user/email/verification`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch((error) => {
      if (error.response?.data?.Error) {
        throw new Error(error.response.data.Error);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};

// Function to register user
export const UserRegister = (Payload) => {
  const data = {
    email: Payload.email,
    password: Payload.password,
  };
  console.log(data);
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/register/user`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      return response;
    })
    .catch((error) => {
      if (error.response?.data?.Error) {
        throw new Error(error.response.data.Error);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};

// Function to request user login
export const SignInApi = (Payload) => {
  let data = Payload;

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/user/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      // console.log((response.data));
      return response;
    })
    .catch((error) => {

      console.log("error login",error)
     if (error.response.data.error) {
        throw new Error(error.response.data.error);
      } else if (error.message) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};

export const AdminStaffProfile = (key) => {
  

  let config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/admin-staff-data/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${key}`,
    },
   
  };

  return axios
    .request(config)
    .then((response) => {
      // console.log((response.data));
      return response;
    })
    .catch((error) => {
      console.log("error", error)
      if (error.response.data[0]?.Error) {
        throw new Error(error.response.data[0]?.Error);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};
export const AdminStaffProfileX = () => {
  

  let config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/admin-staff-data/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
    },
   
  };

  return axios
    .request(config)
    .then((response) => {
      // console.log((response.data));
      return response;
    })
    .catch((error) => {
      console.log("error", error)
      if (error.response.data[0]?.Error) {
        throw new Error(error.response.data[0]?.Error);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};

export const AdminDashboardDataApi = () => {
  

  let config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/dashboard/layout/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
    },
   
  };

  return axios
    .request(config)
    .then((response) => {
      // console.log((response.data));
      return response;
    })
    .catch((error) => {
      console.log("error", error)
      if (error.response.data[0]?.Error) {
        throw new Error(error.response.data[0]?.Error);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};

export const UserProfile = (key) => {
  

  let config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/user/me/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${key}`,
    },
   
  };

  return axios
    .request(config)
    .then((response) => {
      // console.log((response.data));
      return response;
    })
    .catch((error) => {
      console.log("error", error)
      if (error.response.data[0]?.Error) {
        throw new Error(error.response.data[0]?.Error);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};


export const UserProfileX = () => {
  

  let config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/user/me/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
    },
   
  };

  return axios
    .request(config)
    .then((response) => {
      // console.log((response.data));
      return response;
    })
    .catch((error) => {
      console.log("error", error)
      if (error.response.data[0]?.Error) {
        throw new Error(error.response.data[0]?.Error);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};

// Function to request user logout
export const userLogout = (navigate) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/user/logout`,
    headers: {
      "Content-Type": "application/json",
     
    },
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("Logout error:", error);
    });
};


export const CandidateProfile = (Payload, method) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/candidate-profile/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,

     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("CandidateProfile error:", error);
    });
};

export const CreateAdminStaffProfile = (Payload) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/staff-admin/profile/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,

     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("CandidateProfile error:", error);
    });
};

export const UploadProfileImgAPI = (Payload) => {
  console.log("base64api", token)
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/candidate-profile-image/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,

     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("UploadProfileImgAPI error:", error);
    });
};

export const CreateEmbassyApi = (Payload) => {

console.log("CreateEmbassyApi",Payload)
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/embassy/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,

     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("CandidateProfile error:", error);
      if (error.response.data) {
        throw new Error("please make sure all field are filled");
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};
export const EditEmbassyApi = (Payload,id) => {

console.log("EditEmbassyApi",Payload)
  const config = {
    method: "PATCH",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/embassy/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,

     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("EditEmbassyApi error:", error);
      // if (error.response.data) {
      //   throw new Error("please make sure all field are filled");
      // } else if (error.request) {
      //   throw new Error(error.message);
      // } else {
      //   throw new Error(error.message);
      // }
    });
};
export const ApproveReservationAPI = (Payload,id) => {

 

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/reservation-review/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,

     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("CandidateProfile error:", error);
      if (error.response.data) {
        throw new Error("please make sure all field are filled");
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};



export const GetCountries = (Payload) => {
  
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://countriesnow.space/api/v0.1/countries`,
    headers: {
      "Content-Type": "application/json",
      // Authorization : `Bearer Iicc07k4EJKIy_HfkaO1VsKKa5RLvKYCJDSelX43DWitFY13Uz1hau79LHmBSe4TULE`,

     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error(" error:", error);
    });
};

export const GetReservationAPI = (Payload) => {
  
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/user-reservation/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error(" error:", error);
    });
};

export const GetStaffReservationAPI = () => {

  console.log("token res",token)
  
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/staff-reservation/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
  
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error(" GetStaffReservationAPI:", error);
    });
};
export const GetStaffListAPI = () => {
  
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/staff/user-list/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
    data: JSON.stringify()
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error(" error:", error);
    });
};
export const GetUsersListAPI = () => {
  
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/users/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
    data: JSON.stringify()
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error(" error:", error);
    });
};
export const GetAdminListAPI = () => {
  
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/users/admin`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
    data: JSON.stringify()
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error(" error:", error);
    });
};
export const GetInstanceAPI = (reservationNo) => {
  
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}/biometrics/api/v1/instance/${reservationNo}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
    data: JSON.stringify()
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error(" GetInstanceAPI:", error);
      if (error.response.data.message) {
        throw new Error(error.response.data.message);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};
export const ConvertBase64API = (payload) => {
  
  const config = {
    method: "Post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/b64/converter/`,
    headers: {
      "Content-Type": "application/json",
     
     
    },
    data: JSON.stringify(payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error(" ConvertBase64API:", error);
      if (error.response.data.message) {
        throw new Error(error.response.data.message);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};


export const GetEmbassy = () => {
  const config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/embassy/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,

     
    },
    
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("GetEmbassy:", error);
    });
};

export const GetCollectionTokenAPI = (Payload,id) => {
  console.log("PayloadPhone", id)
  const config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: `${baseURL}/payments/api/v1/momo/request-to-pay/${id}/ `,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,  
     
      
    },
    data: JSON.stringify(Payload)
    
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("GetEmbassy:", error);
    });
};
export const ApproveStaffAPI = (id,method) => {
  const config = {
    method: method,
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/diasble/users/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
    
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("ApproveStaffAPI:", error);
    });
};


export const GlobalPayAPI = (Payload) => {
  console.log("globalPayload", Payload)
  const config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: `https://demo.globalpay.com.ng/globalpay_demo/Paymentgatewaycapture.aspx`,
    headers: {
      "Content-Type": "application/json",
    
    },
    data: JSON.stringify(Payload)
    
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("GlobalPayAPI:", error);
    });
};

export const CreateReservationAPI = (payload) => {

  console.log("idres", payload)
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/reservation/${payload.id}/${payload.reference}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("CreateReservation error:", error);
      if (error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });

  };

export const CreateStaffAPI = (Payload,id) => {

  console.log("id res", id)
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/register-staff/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("CreateStaffAPI error:", error);
      if (error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};
export const CreateAdminAPI = (Payload) => {

  console.log("email payload", Payload)
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/auth/api/v1/users/admin`,
    headers: {
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("CreateStaffAPI error:", error);
      if (error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};
export const SendMessageAPI = (Payload) => {

  console.log("sendMSGPayload", Payload)
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/main/api/v1/email/support/`,
    headers: {
      "Content-Type": "application/json",
      // Authorization : `Bearer ${token}`,
     
    },
    data: JSON.stringify(Payload)
  };

  return axios
    .request(config)
    .then((res) => {
    
     return res
     
    })
    .catch((error) => {
      console.error("SendMessageAPI error:", error);
      if (error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};