import React from "react";
import Input from "../Components/Input";
import Button from "../Components/Button";
import { Flex, InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";

function SearchBar({
  w="100px",
  mt="32px",
  placeholder,
  handleSearch,
  isLoading,
  value,
  btnText,
  rounded = "0px 8px 8px 0px",
  onClick,
  loadingText,
  mb
}) 

    
{

    

  return (
    <InputGroup mt={mt} mb={mb}>
      <Input
        type="text"
        placeholder={placeholder}
        onChange={handleSearch}
        value={value}
        leftIcon={<BiSearch/>}
      />
      <InputRightElement w={w}>
        <Button isLoading={isLoading} loadingText={loadingText} mr="68px" rounded={rounded} onClick={onClick} mt="7px" size="lg">
          {btnText}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}

export default SearchBar;
