import React from "react";
import DashboardLayout from "../Layout";

const AttestUser = () => {
  return <DashboardLayout>
    <h1>Attest User</h1>
  </DashboardLayout>;
};

export default AttestUser;
