import React, { useState } from "react";
import { Avatar, Box, Checkbox, IconButton, Text } from "@chakra-ui/react";
import { MdDelete, MdOutlinePending } from "react-icons/md";

export function SupportItem({ name, email, issue, date, text, isLastItem, status }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box
      padding="10px"
      borderLeft={{ base: "none", md: "1px solid #e2e8f0" }}
      paddingX="15px"
      _hover={{ backgroundColor: "#e2e8f0" }}
      mt="10px"
      rounded="4px"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      position="relative"
      boxShadow="md"
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="row"
          gap="10px"
          alignItems="flex-start"
        >
          <Checkbox borderColor={"gray"}> </Checkbox>
          <Box display="flex" flexDirection="column" w="100%">
            <Text fontSize={{ base: "12px", md: "14px" }}>{name}</Text>
            <Text color="blue" fontSize={{ base: "10px", md: "12px" }}>
              {email}
            </Text>
            <Text
              fontWeight="600"
              fontSize={{ base: "10px", md: "16px" }}
              mt="10px"
            >
              {issue}
            </Text>
            <Text fontSize={{ base: "10px", md: "12px" }} mt="10px">
              {text}
            </Text>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" gap="5px">
            <MdOutlinePending size={20} color="blue" />
            <Text color="blue" fontSize={{ base: "12px", md: "14px" }}>
              {status}
            </Text>
          </Box>

          <Text
            color="gray"
            textAlign="right"
            fontSize={{ base: "10px", md: "8px" }}
            mt="15px"
          >
            {date}
          </Text>
        </Box>

        {isHovered && (
          <IconButton
            icon={<MdDelete />}
            aria-label="Delete"
            size="sm"
            position="absolute"
            top="50%"
            right="5px"
            transform="translateY(-50%)"
            colorScheme="red"
          />
        )}
      </Box>
    </Box>
  );
}
