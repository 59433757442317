import { Text } from "@chakra-ui/layout";
import React from "react";

export default function HeaderTitle({ title, mt }) {
  return (
    <Text
      textTransform={"capitalize"}
      mt={mt}
      pos="relative"
      fontSize={"16px"}
      fontWeight="700"
      letterSpacing={"1px"}
      _before={{
        h: "3px",
        pos: "absolute",
        bottom: "0",
        left: "0",
        bg: "blue.blue500",
        w: "48px",
        top: "25px",
        content: '" "',
      }}
      color="blue.blue600"
    >
      {title}
    </Text>
  );
}
