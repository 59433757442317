import { Box, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Button from '../Button'
import { ApproveStaffAPI, CreateAdminAPI, CreateReservationAPI, CreateStaffAPI, GetEmbassy } from '../../Utils/ApiCalls';
import { toast } from 'react-toastify';
import Input from '../Input';
import { AiTwotoneMail } from 'react-icons/ai';


export default function ConfirmationModel({ isOpen, onClose, type, id, status }) {
    const [Payload, setPayload] = useState({
        embassy: "",
        email: ""
    });
    const [EmbassyList, setEmbassyList] = useState([]);
    const [Loading, setLoading] = useState(false);

    const handlePayload = (e) => {
        setPayload({
            ...Payload, [e.target.id]: e.target.value
        })
    }

    const proceed = async (e) => {
        e.preventDefault()

        setLoading(true)
        try {

            const result = await CreateStaffAPI({email: Payload.email}, Payload.embassy);
            console.log("CreateReservation", result);
            setLoading(false)
            if (result.status === 200) {

                toast.success("Staff has been created successfully");
                onClose()
            } else {

            }
        } catch (error) {
            setLoading(false)

            toast.error(error.message);
        }


    }



    const ApproveStaff = async () => {
        setLoading(true)
        try {
            const result = await ApproveStaffAPI(id,status? "POST":"PATCH");
            console.log("ApproveStaff", result);
            if (result.status === 200) {
                setLoading(false)
                onClose()
                toast.success("Staff has been updated successfully");
            } else {

            }
        } catch (error) {

            toast.error(error.message);
            setLoading(false)
        }
    }
    const ApproveAdmin = async () => {
        setLoading(true)
        try {
            const result = await ApproveStaffAPI(id,status? "POST":"PATCH");
            console.log("ApproveStaff", result);
            if (result.status === 200) {
                setLoading(false)
                onClose()
                toast.success("Staff has been updated successfully");
            } else {

            }
        } catch (error) {

            toast.error(error.message);
            setLoading(false)
        }
    }






    useEffect(() => {
      
    }, [isOpen]);


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Are you sure you want to {status ? "disable":"enable" }</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Box my="20px">


                        <Flex justifyContent={"space-between"}>
                            <Button w="auto" isLoading={Loading} onClick={ApproveStaff}>Yes</Button>
                            <Button background='red' w="auto" onClick={onClose}>No</Button>
                        </Flex>
                     

                    </Box>

                </ModalBody>


            </ModalContent>

        </Modal>
    )
}
