import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import logo from "../assest/nigeriaLogo.png";
import coatOfArm from "../assest/coatOfArm.jpg";
import NavItem from "./NavItem";
import { BiMenuAltRight, BiSolidContact } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FaProductHunt } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { IoMdPricetag } from "react-icons/io";
import Button from "../Components/Button";
import DrawerNavItem from "../Components/DrawerNavItem";

export default function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const list = [
    {
      text: "Home",
      link: "/",
      icon: <AiFillHome />,
    },
   
   
    {
      text: "contact",
      link: "#contact",
      icon: <BiSolidContact />,
    },
  ];

  const nav = useNavigate();
  return (
    <Flex
      py="10px"
      bg="#fff"
      zIndex={"12"}
      px="3%"
      justifyContent={"space-between"}
      position={"fixed"}
      top={"0"}
      w={"100%"}
    >
      <Image pl='15px' src={logo} w={["20%", "13%", "8%", "5%", "8%"]} />

      <Flex
        display={["none", "none", "flex", "flex", "flex"]}
        justifyContent={"flex-end"}
        gap={"10"}
        cursor={"pointer"}
        alignItems={"center"}
        color={"#333"}
      >
        {list.map((item, i) => (
          <NavItem text={item.text} link={item.link} />
        ))}

        <Image src={coatOfArm} w={["20%", "13%", "8%", "10%", "10%"]} />

        
      </Flex>

      <Box
        onClick={() => onOpen()}
        color={"#fff"}
        fontSize={"28px"}
        alignItems={"center"}
        display={["flex", "flex", "none", "none", "none"]}
      >
        <BiMenuAltRight />
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        blockScrollOnMount={true}
        bg="black"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton bg={"gray.gray600"} color="#fff" mt={"17px"} />
          <DrawerHeader>
            <Image src={logo} w={"20%"} />
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing={"12px"} mt="64px">
              {list.map((item, i) => (
                <DrawerNavItem
                  text={item.text}
                  link={item.link}
                  icon={item.icon}
                />
              ))}
              <Button w="auto" onClick={() => nav("/login")}>
                Enroll now
              </Button>
            </Stack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
