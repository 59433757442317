import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './Utils/Theme';
import { AuthProvider } from 'react-auth-kit';
import { Provider } from 'react-redux';
import Store from './Redux/Store';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <ChakraProvider theme={theme}>
    
      <AuthProvider>
        <App />
      </AuthProvider>
      
    </ChakraProvider>
  </React.StrictMode>
);
