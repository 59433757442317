import {
  MdDashboard,
  MdNotifications,
  MdOutlineAnalytics,
  MdToken,
} from "react-icons/md";
import {
  RiAccountCircleFill,
  RiProfileLine,
  RiSettings2Fill,
} from "react-icons/ri";
import { IoIosCreate } from "react-icons/io";
import { TbPlugConnected } from "react-icons/tb";
import { FaMoneyBillAlt, FaUsers, FaWallet } from "react-icons/fa";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { FaPeopleRoof } from "react-icons/fa6";
import { HiMiniRectangleGroup } from "react-icons/hi2";
import { AiFillDashboard } from "react-icons/ai";
import { MdContactSupport } from "react-icons/md";
import {
  activeScreen,
  isAdminUser,
  isAdminUsers,
  isStaff,
  isSuperAdmin,
  isUser,
} from "../Auth";
import { useState } from "react";
import {
  BsCreditCardFill,
  BsFillCloudUploadFill,
  BsLifePreserver,
  BsLightbulbFill,
} from "react-icons/bs";

export const Navlist = (location) => {
  let List = [
    {
      name: "Dashboard",
      icon: <AiFillDashboard />,
      link: "/dashboard",
      active: activeScreen(location, "/dashboard"),
      display: true,
    },
    {
      name: "Profile",
      icon: <RiProfileLine />,
      link: "/profile",
      active: activeScreen(location, "/profile"),
      display: true,
    },
    {
      name: "Help & Support",
      icon: <MdContactSupport />,
      link: "/help-support",
      active: activeScreen(location, "/help-support"),
      display: isUser(),
    },
    {
      name: "Reservation",
      icon: <BsLifePreserver />,
      link: "#",
      active: activeScreen(location, "#"),
      display: isUser(),
      children: [
        {
          name: "Make reservation",
          link: "/dashboard/reservation/make-reservation",
          active: activeScreen(location, "/reservation/make-reservation"),
        },
      ],
    },

    // ADMIN
    {
      name: "Users",
      icon: <FaUsers />,
      link: "#",
      active: activeScreen(location, "#"),
      display: isAdminUsers(),
      children: [
        {
          name: "User list",
          icon: <MdOutlineAnalytics />,
          link: "/dashboard/users/user-list",
          active: activeScreen(location, "/users/user-list"),
        },
        // {
        //   name: "Attest user",
        //   icon: <MdOutlineAnalytics />,
        //   link: "/dashboard/users/attest-user",
        //   active: activeScreen(location, "/users/attest-user"),
        // },
      ],
    },

    {
      name: "Admin",
      icon: <FaUsers />,
      link: "#",
      active: activeScreen(location, "#"),
      display: isSuperAdmin(),
      children: [
        {
          name: "Admin list",
          icon: <MdOutlineAnalytics />,
          link: "/dashboard/admin/admin-list",
          active: activeScreen(location, "/admin/admin-list"),
        },
      ],
    },

    {
      name: "Reservations",
      icon: <RiAccountCircleFill />,
      downIcon: <BiCaretDown />,
      upIcon: <BiCaretUp />,
      link: "#",
      onClick: "",
      active: activeScreen(location, "#"),
      display: isStaff(),

      children: [
        {
          name: "Reservation List",
          icon: <MdOutlineAnalytics />,
          link: "/reservation/reservation-list",
          active: activeScreen(location, "/reservation/reservation-list"),
          display: true,
        },
      ],
    },

    {
      name: "Biometrics",
      icon: <BsFillCloudUploadFill />,
      active: activeScreen(location, "#"),
      display: isStaff(),

      children: [
        {
          name: "biometrics upload",
          icon: <BsFillCloudUploadFill />,
          link: "/biometerics/biometerics-upload",
          active: activeScreen(location, "/biometerics/biometerics-upload"),
          display: true,
        },
      ],
    },

    // support for staff

    {
      name: "Embassy",
      icon: <HiMiniRectangleGroup />,
      downIcon: <BiCaretDown />,
      upIcon: <BiCaretUp />,
      link: "#",
      onClick: "",
      active: activeScreen(location, "#"),
      display: isAdminUsers(),

      children: [
        {
          name: "Embassy List",
          icon: <MdOutlineAnalytics />,
          link: "/embassy/embassy-list",
          active: activeScreen(location, "/embassy/embassy-list"),
          display: true,
        },
      ],
    },

    {
      name: "Card Management",
      icon: <BsCreditCardFill />,
      downIcon: <BiCaretDown />,
      upIcon: <BiCaretUp />,
      link: "#",
      onClick: "",
      active: activeScreen(location, "#"),
      display: isAdminUsers(),
      children: [
        {
          name: "Card Printing",
          icon: <MdOutlineAnalytics />,
          link: "/card-management/card-printing",
          active: activeScreen(location, "/card-management/card-printing"),
          display: true,
        },
      ],
    },
    
    {
      name: "Staffs & Admins",
      icon: <FaPeopleRoof />,
      downIcon: <BiCaretDown />,
      upIcon: <BiCaretUp />,
      link: "#",
      onClick: "",
      active: activeScreen(location, "#"),
      display: isAdminUsers(),
      children: [
        {
          name: "Manage Admins",
          icon: <MdOutlineAnalytics />,
          link: "/staffs-&-admins/manage-admins",
          active: activeScreen(location, "/staffs-&-admins/manage-admins"),
          display: true,
        },
        {
          name: "Manage Staffs",
          icon: <MdOutlineAnalytics />,
          link: "/staffs-&-admins/manage-staffs",
          active: activeScreen(location, "/staffs-&-admins/manage-staffs"),
          display: true,
        },
      ],
    },
    {
      name: "Help & Support",
      icon: <MdContactSupport />,
      link: "/admin-help-support",
      active: activeScreen(location, "/admin-help-support"),
      display: isAdminUsers(),
    },
  ];

  return List;
};
