import { Avatar, Box, Flex, FormControl, Image, Select, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react'
import Headers from './Header';
import test from "../assest/test.jpg"
import Input from './Input';
import Button from './Button';
import { AdminStaffProfileX, CandidateProfile, CreateAdminStaffProfile, GetCountries, UploadProfileImgAPI } from '../Utils/ApiCalls';
import { useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { BsPenFill, BsTelephoneFill, BsUpload } from 'react-icons/bs';
import { FaPen, FaUser, FaUserAlt } from 'react-icons/fa';
import { BiSolidCloudUpload } from 'react-icons/bi';
import { toast } from 'react-toastify';

export default function AdminStaffProfile() {

  const [Payload, setPayload] = useState({

    title: "",
    first_name: "",
    last_name: "",
    gender: "",
    dob: "",
    country_birth: "",
    city_birth: "",
    community: "",
    maiden_name: "",
    education: "",
    marital_status: "",
    no_children: "",
    profile_photo: "",
    passport_no: "",
    passport_date: "",
    nin: "",
    phone: "",
    whatsapp_no: "",
    address_nigeria: "",
    current_location: "",
    kin_name: "",
    kin_address: "",
    kin_phone: "",
    kin_email: "",
    relationship: "",
    signature: "",
    height: ""



  });

  const [Countries, setCountries] = useState([]);
  const [ReadOnly, setReadOnly] = useState(true);
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Uploading, setUploading] = useState(false);

  const onlineUser = JSON.parse(sessionStorage.getItem("profileDetails"))
  const profile = JSON.parse(sessionStorage.getItem("profileDetails"))?.image_data?.image

  // let profileIMG = windowURL.createObjectURL(profile)

  const handlePayload = (e) => {
    setPayload({ ...Payload, [e.target.id]: e.target.value })

  }


  const [base64Image, setBase64Image] = useState('');
  const [ImagePic, setImage] = useState(null);



  const handleImg = (e) => {
    setImage(null)
    let file = e.target.files[0]

    let url = URL.createObjectURL(file)

    setImage(url)


    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setBase64Image(base64Data);
      };
      reader.readAsDataURL(file);
    }
    
  }


  const getAdminStaffDetails = async ()=>{

    try {
      const result = await AdminStaffProfileX();
      // console.log("UserProfile", result);
      if (result.status === 200) {
        // setLoading(false);
        setUploading(false)
        sessionStorage.setItem("profileDetails", JSON.stringify(result.data))
        setImage(null)
        setLoading(false)
        toast.success("Profile Submitted successful!");
        window.location.reload()
     
      } else {
       
      }
    } catch (error) {
      
      toast.error(error.message);
    }
  }

  const UploadImg = async () => {
    setUploading(true)
    try {
      const result = await UploadProfileImgAPI({image: base64Image});
      console.log("UploadProfileImgAPI", result);
      if (result.status === 200) {
       
        getAdminStaffDetails()
       
      } else {
        setUploading(false)
      }
    } catch (error) {
      setUploading(false)
      
      toast.error(error.message);
    }
  }



  const SubmitProfile = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const result = await CreateAdminStaffProfile(Payload);
      console.log("CandidateProfile", result);
      if (result.status === 200) {

        getAdminStaffDetails()
        
        



      } else {
        setLoading(false)

      }
    } catch (error) {
      setLoading(false)

      toast.error(error.message);
    }

  }


  useEffect(() => {

    setPayload({

      first_name: onlineUser?.profile_data?.first_name,
      last_name: onlineUser?.profile_data?.last_name,
      phone: onlineUser?.profile_data?.phone,
  })

  }, []);
  return (
    <Box mt="" px="15px" py="15px" bg={"#fff"} rounded="8px" mb="32px">
      {/* <Box rounded="8px" mt="" pos={"relative"} bg={"#efe6eb"} backgroundImage={test} bgRepeat={"no-repeat"} bgPos={"center"} bgSize={"cover"} h="100">
                <Avatar position={"absolute"} left="40px" bottom={"-55px"} size='2xl' name={`User dell`} src='https://bit.ly/prosper-baba' />
            </Box> */}

      <Box pos={"relative"} rounded="8px" mt="" bg={"#efe6eb"} backgroundImage={test} bgRepeat={"no-repeat"} bgPos={"center"} bgSize={"cover"} h="100">
        <label for="upload">
          <Avatar position={"absolute"} left="40px" bottom={"-30px"} pos={"relative"} name={`${onlineUser?.profile?.first_name} ${onlineUser?.profile?.last_name}`} src={ImagePic || `${profile}`} size='2xl'>
            <Box color={"#242424"} pos={"absolute"} right={"-18px"} top={"70px"}>
              <Input onChange={handleImg} type="file" id="upload" hidden />
              <Box bgColor={"#ddd"} cursor="pointer" rounded="100" display="flex" justifyContent={"center"} alignItems={"center"} w="30px" h="30px">
                <label className='label2' for="upload">
                  <Box fontSize={"12px"} cursor="pointer" color="#242424" ><FaPen /></Box>
                </label>
              </Box>
            </Box>
          </Avatar>
        </label>
      </Box>

      {
        ImagePic && (

          <Flex justifyContent={"flex-end"} mt="15px">

            <Button px='8px' isLoading={Uploading} onClick={UploadImg} loadingText='Uploading...' w='auto' ><BsUpload /></Button>
          </Flex>
        )
      }




      {
        onlineUser?.user?.profile_status === false ? (
          <>
            <Headers text={"Complete the information to Complete Profile"} mt="72px" />
          </>

        ) : (
          <>
            <Headers text={"Edit profile"} mt="72px" />
          </>

        )
      }
      <form onSubmit={SubmitProfile}>
        <Flex justifyContent={"flex-end"}>

          <Button leftIcon={<BsPenFill />} py="15px" size='sm' px='8px' onClick={() => setReadOnly(!ReadOnly)} w='auto' > {ReadOnly ? "on edit" : "off edit"}</Button>
        </Flex>
        <Flex justifyContent={"space-between"} mt="12px" flexWrap={"wrap"}>

          <Box w={["100%", "100%", "48%", "48%", "48%"]}>

            <Stack mt="12px" spacing="15px" >

              <Input bColor={ReadOnly === false ? "#242424" : "#ddd"} leftIcon={<FaUser />} borderColor={ReadOnly === false ? "#017CC2" : "#ddd"} readOnly={ReadOnly} label='First name' id='first_name' type='text' value={Payload.first_name} val={Payload.first_name !== "" ? true : false} onChange={handlePayload} />
              <Input bColor={ReadOnly === false ? "#242424" : "#ddd"} leftIcon={<FaUserAlt/>} borderColor={ReadOnly === false ? "#017CC2" : "#ddd"} readOnly={ReadOnly} label='Last name' id='last_name' type='text' value={Payload.last_name} val={Payload.last_name !== "" ? true : false} onChange={handlePayload} />

            </Stack>

          </Box>
          <Box w={["100%", "100%", "48%", "48%", "48%"]}>

            <Stack mt="12px" spacing="15px">

              <Input leftIcon={<BsTelephoneFill/>} bColor={ReadOnly === false ? "#242424" : "#ddd"} borderColor={ReadOnly === false ? "#017CC2" : "#ddd"} readOnly={ReadOnly} label='Phone No' id='phone' type='text' value={Payload.phone} val={Payload.phone !== "" ? true : false} onChange={handlePayload} />

            </Stack>

          </Box>


        </Flex>
        {
          !ReadOnly && (

            <Button isLoading={Loading} isSubmit={true} mt={"32px"}>Submit</Button>
          )
        }
      </form>
    </Box>
  )
}
