import React, { useEffect, useState } from "react";
// import DashboardLayout from "../../Layout/Index";
import DashboardLayout from "../../Layout/index";
import Seo from "../../Utils/Seo";
import { SupportItem } from "./SupportItem";
import SearchBar from "../../Components/SearchBarr";
import { Box, Flex } from "@chakra-ui/react";
import FilterBtn from "../../Components/FilterBtn";
// import { GetAdminSupportApi } from "../../../Utils/ApiCall";
// import Preloader from "../../../Component/Preloader";
// import { showToast } from "../../../Utils/ToastTools";
import formatDate from "../../Components/FornatDate";

export function AdminSupport() {
  const [IsLoading, setIsLoading] = useState(true);
  const [msgs, setMsgs] = useState([]);
  // console.log("adminSupport", msgs);
  const messages = [
    {
      id: 1,
      name: "Femi Adeleke",
      email: "femi@gmail.com",
      issue: "Payment",
      date: "Sept. 20, 2023",
      text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 2,
      name: "Solomon Adeleke",
      email: "solomon@gmail.com",
      issue: "Verification",
      date: "Sept. 20, 2023",
      text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 3,
      name: "Femi Adeleke",
      email: "femi@gmail.com",
      issue: "Payment",
      date: "Sept. 20, 2023",
      text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 4,
      name: "Solomon Adeleke",
      email: "solomon@gmail.com",
      issue: "Support",
      date: "Sept. 20, 2023",
      text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ];

  // useEffect(() => {
  //   AdminSupportData();
  // }, []);

  // const AdminSupportData = async () => {
  //   setIsLoading(true);
  //   try {
  //     let result = await GetAdminSupportApi();
  //     // console.log("Res userSupport", result);
  //     if (result.status === 200) {
  //       setMsgs(result.data);
  //       setIsLoading(false);
  //     }
  //   } catch (e) {
  //     setIsLoading(false);

  //     showToast({
  //       type: "error",
  //       message: e.message,
  //     });
  //   }
  // };

  return (
    <DashboardLayout>
      <Seo
        title="Admin Support"
        description="Dannon Verification Admin Support page"
      />

      {/* {IsLoading && <Preloader />} */}

      <Box
        bg="white"
        padding="20px"
        rounded="5px"
        boxShadow="2px 5px 10px 2px #BDBDBD"
        mt="20px"
      >
        <Flex justifyContent="space-between" flexWrap="wrap">
          <SearchBar
            placeholder="Search Support..."
            rounded="4px"
            text="All"
            btnText="Search"
          />
          <FilterBtn text="Filter" userType="Filter" />
        </Flex>

        {messages.map((message, index) => (
          <SupportItem
            key={message.id}
            // name={`${message?.user_data?.first_name} ${message?.user_data?.last_name}`}
            name={message.name}
            // email={message?.user_data?.email}
            email={message.email}
            issue={message.issue}
            // date={formatDate(message?.created_at)}
            date={message.date}
            // text={message?.message}
            text={message.text}
            // status={message?.status}
            status={"Pending"}
            isLastItem={index === msgs.length - 1}
          />
        ))}
      </Box>
    </DashboardLayout>
  );
}
