import React from 'react'
import DashboardLayout from '../Layout'

const ManageRoles = () => {
  return (
    <DashboardLayout>
        <h1>Manage Roles</h1>
    </DashboardLayout>
  )
}

export default ManageRoles