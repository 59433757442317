import React, { useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import DashBoardContent from "../Components/DashBoardContent";
import UserList from "./UserList";
import DashboardLayout from "../Layout";
import { AdminDashboardDataApi } from "../Utils/ApiCalls";
import { toast } from "react-toastify";

export default function Dashboard() {


  const loggedInUser = JSON.parse(sessionStorage.getItem("profileDetails"));

  const newUser = JSON.parse(sessionStorage.getItem("UserDetails"));

  console.log("check",newUser);



  return <DashboardLayout>

    <Text mt="22px" fontWeight="600">Welcome {loggedInUser?.detail?.profile?.first_name||newUser?.email} 👋</Text>
      <DashBoardContent/>
  </DashboardLayout>;
}

