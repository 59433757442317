import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import { Outlet, useLocation } from "react-router-dom";

const DashboardLayout = ({ bgColor = "gray.gray100" , children}) => {
  const [Open, setOpen] = useState(false);
  const [Width, setWidth] = useState({
    side: "20%",
    main: "80%",
  });

  const location = useLocation().pathname.replace(/\//g, "> ");


  const handleIsOpen = () => {
    setOpen(!Open);
  };

  useEffect(() => {
    if (Open === true) {
      setWidth({
        side: "5%",
        main: "95%",
      });
    } else {
      setWidth({
        side: "20%",
        main: "80%",
      });
    }
  }, [Open]);

  return (
    <Box>
      <Flex justifyContent="space-between" align="flex-start">
        <Box
          display={["none", "none", "none", "block", "block"]}
          bg="white"
          w={Width.side}
          transition={'0.4s ease'}
          // overflow=""
        >
          <Sidebar open={Open} handleOpen={handleIsOpen} />
        </Box>
        <Box bg={bgColor  } w={["100%", "100%", "100%", Width.main, Width.main]} minH="100vh">
          <Box px="2%" >
            <Navbar />
            {/* <p>{location}</p> */}
            {children}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardLayout;
