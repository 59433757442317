import {
  Avatar,
  Box,
  Flex,
  FormControl,
  Image,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Headers from "./Header";
import test from "../assest/test.jpg";
import Input from "./Input";
import Button from "./Button";
import {
  CandidateProfile,
  GetCountries,
  UploadProfileImgAPI,
  UserProfileX,
} from "../Utils/ApiCalls";
import { useEffect } from "react";
import { AiFillEdit } from "react-icons/ai";
import {
  BsCalendarDateFill,
  BsFillCalendarDateFill,
  BsFillCloudUploadFill,
  BsFillTelephoneInboundFill,
  BsPenFill,
  BsUpload,
} from "react-icons/bs";
import {
  FaCalendarAlt,
  FaPassport,
  FaPen,
  FaTextHeight,
  FaUser,
  FaUserCircle,
  FaUserFriends,
} from "react-icons/fa";
import { GiBodyHeight } from "react-icons/gi";
import { toast } from "react-toastify";
import {
  MdCastForEducation,
  MdConfirmationNumber,
  MdEmail,
} from "react-icons/md";
import { FaChildren, FaLocationPin, FaFileSignature } from "react-icons/fa6";

export default function UserProfilePage() {
  const [Payload, setPayload] = useState({
    title: "",
    first_name: "",
    last_name: "",
    gender: "",
    dob: "",
    country_birth: "",
    city_birth: "",
    maiden_name: "",
    education: "",
    marital_status: "",
    no_children: "",
    profile_photo: "",
    passport_no: "",
    passport_date: "",
    nin: "",
    phone: "",
    address_nigeria: "",
    current_location: "",
    kin_name: "",
    kin_address: "",
    kin_phone: "",
    kin_email: "",
    relationship: "",
    signature: "",
    height: "",
  });

  const [Countries, setCountries] = useState([]);
  const [ReadOnly, setReadOnly] = useState(true);
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);

  const handlePayload = (e) => {
    setPayload({ ...Payload, [e.target.id]: e.target.value });
  };

  const [base64Image, setBase64Image] = useState("");
  const [ImagePic, setImage] = useState(null);

  const [Signature, setSignature] = useState("");

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSignature(file.name);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setBase64Image(base64Data);
        setPayload((prevState) => ({ ...prevState, signature: base64Data }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImg = (e) => {
    setImage(null);
    let file = e.target.files[0];

    let url = URL.createObjectURL(file);

    setImage(url);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setBase64Image(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  const profile = JSON.parse(sessionStorage.getItem("profileDetails"))
    ?.image_data?.image;

  const getCountries = async () => {
    try {
      const result = await GetCountries();

      if (result.status === 200) {
        setCountries(result.data.data);
      } else {
      }
    } catch (error) {
      // toast.error(error.message);
    }
  };

  const getUSerDetails = async () => {
    try {
      const result = await UserProfileX();
      // console.log("UserProfile", result);
      if (result.status === 200) {
        // setLoading(false);
        sessionStorage.setItem("profileDetails", JSON.stringify(result.data));
        setUploading(false);
        setImage(null);
        setLoading(false);

        toast.success("Profile Submitted successful!");
      } else {
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onlineUser = JSON.parse(sessionStorage.getItem("profileDetails"));

  const [Uploading, setUploading] = useState(false);

  const UploadImg = async () => {
    setUploading(true);
    try {
      const result = await UploadProfileImgAPI({ image: base64Image });
      console.log("UploadProfileImgAPI", result);
      if (result.status === 200) {
        getUSerDetails();
      } else {
        setUploading(false);
      }
    } catch (error) {
      setUploading(false);

      toast.error(error.message);
    }
  };

  const SubmitProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await CandidateProfile(Payload);
      console.log("CandidateProfile", result);
      if (result.status === 200) {
        getUSerDetails();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      toast.error(error.message);
    }
  };

  useEffect(() => {
    getCountries();

    setPayload({
      title: onlineUser?.profile_data?.title,
      first_name: onlineUser?.profile_data?.first_name,
      last_name: onlineUser?.profile_data?.last_name,
      gender: onlineUser?.profile_data?.gender,
      dob: onlineUser?.profile_data?.dob,
      country_birth: onlineUser?.profile_data?.country_birth,
      city_birth: onlineUser?.profile_data?.city_birth,
      maiden_name: onlineUser?.profile_data?.maiden_name,
      education: onlineUser?.profile_data?.education,
      marital_status: onlineUser?.profile_data?.marital_status,
      no_children: onlineUser?.profile_data?.no_children,
      passport_no: onlineUser?.profile_data?.passport_no,
      passport_date: onlineUser?.profile_data?.passport_date,
      nin: onlineUser?.profile_data?.nin,
      phone: onlineUser?.profile_data?.phone,
      address_nigeria: onlineUser?.profile_data?.address_nigeria,
      current_location: onlineUser?.profile_data?.current_location,
      kin_name: onlineUser?.profile_data?.kin_name,
      kin_address: onlineUser?.profile_data?.kin_address,
      kin_phone: onlineUser?.profile_data?.kin_phone,
      kin_email: onlineUser?.profile_data?.kin_email,
      relationship: onlineUser?.profile_data?.relationship,
      signature: onlineUser?.profile_data?.signature,
      height: onlineUser?.profile_data?.height,
    });
  }, []);
  return (
    <Box mt="" px="15px" py="15px" bg={"#fff"} rounded="8px" mb="32px">
      {/* <Box rounded="8px" mt="" pos={"relative"} bg={"#efe6eb"} backgroundImage={test} bgRepeat={"no-repeat"} bgPos={"center"} bgSize={"cover"} h="100">
                <Avatar position={"absolute"} left="40px" bottom={"-55px"} size='2xl' name={`User dell`} src='https://bit.ly/prosper-baba' />
            </Box> */}

      <Box
        pos={"relative"}
        rounded="8px"
        mt=""
        bg={"#efe6eb"}
        backgroundImage={test}
        bgRepeat={"no-repeat"}
        bgPos={"center"}
        bgSize={"cover"}
        h="100"
      >
        <label for="upload">
          <Avatar
            position={"absolute"}
            left="40px"
            bottom={"-30px"}
            pos={"relative"}
            name={`${onlineUser?.profile?.first_name} ${onlineUser?.profile?.last_name}`}
            src={ImagePic || `${profile}`}
            size="2xl"
          >
            <Box
              color={"#242424"}
              pos={"absolute"}
              right={"-18px"}
              top={"70px"}
            >
              <Input onChange={handleImg} type="file" id="upload" hidden />
              <Box
                bgColor={"#ddd"}
                cursor="pointer"
                rounded="100"
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
                w="30px"
                h="30px"
              >
                <label className="label2" for="upload">
                  <Box fontSize={"12px"} cursor="pointer" color="#242424">
                    <FaPen />
                  </Box>
                </label>
              </Box>
            </Box>
          </Avatar>
        </label>
      </Box>

      {ImagePic && (
        <Flex justifyContent={"flex-end"} mt="15px">
          <Button px="8px" isLoading={Uploading} onClick={UploadImg} w="auto">
            <BsUpload />
          </Button>
        </Flex>
      )}

      {onlineUser?.user?.profile_status === false ? (
        <>
          <Headers
            text={"Complete the information to apply for your card"}
            mt="72px"
          />
          {ReadOnly === false && <Text>Scroll down to submit</Text>}
        </>
      ) : (
        <>
          <Headers text={"Edit profile"} mt="72px" />

          {ReadOnly === false && (
            <Text fontSize={"14px"} fontWeight={"600"} mt="10px">
              Scroll down to submit
            </Text>
          )}
        </>
      )}
      <form onSubmit={SubmitProfile}>
        <Flex justifyContent={"flex-end"}>
          <Button
            leftIcon={<BsPenFill />}
            px="8px"
            onClick={() => setReadOnly(!ReadOnly)}
            w="auto"
          >
            {" "}
            {ReadOnly ? "turn on edit" : "turn off edit"}
          </Button>
        </Flex>
        <Flex justifyContent={"space-between"} mt="12px" flexWrap={"wrap"}>
          <Box w={["100%", "100%", "48%", "48%", "48%"]}>
            <Stack mt="12px" spacing="15px">
              <Select
                isReadOnly={ReadOnly}
                _focus={{ borderColor: "blue.blue400" }}
                size={"lg"}
                borderColor={ReadOnly === false ? "#242424" : "#ddd"}
                placeholder="Select Title"
                fontWeight={"400"}
                fontSize={Payload.title != "" ? "16px" : "12px"}
                id="title"
                value={Payload.title}
                onChange={handlePayload}
              >
                <option value={`mr`}>Mr</option>
                <option value={`mrs`}>Mrs</option>
                <option value={`miss`}>Miss</option>
              </Select>

              <Input
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                leftIcon={<FaUser />}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="First name"
                id="first_name"
                type="text"
                value={Payload.first_name}
                val={Payload.first_name !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<FaUser />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Last name"
                id="last_name"
                type="text"
                value={Payload.last_name}
                val={Payload.last_name !== "" ? true : false}
                onChange={handlePayload}
              />

              <Select
                _focus={{ borderColor: "blue.blue400" }}
                size={"lg"}
                borderColor={ReadOnly === false ? "#242424" : "#ddd"}
                placeholder="Select Gender"
                fontWeight={"400"}
                fontSize={Payload.gender != "" ? "16px" : "12px"}
                id="gender"
                value={Payload.gender}
                onChange={handlePayload}
              >
                <option value={`male`}>Male</option>
                <option value={`female`}>Female</option>
              </Select>

              <Input
                leftIcon={<FaCalendarAlt />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Date of birth"
                id="dob"
                type="date"
                value={Payload.dob}
                val={Payload.dob !== "" ? true : false}
                onChange={handlePayload}
              />

              <Select
                _focus={{ borderColor: "blue.blue400" }}
                size={"lg"}
                borderColor={ReadOnly === false ? "#242424" : "#ddd"}
                placeholder="Country of Birth"
                fontWeight={"400"}
                fontSize={Payload.country_birth != "" ? "16px" : "12px"}
                id="country_birth"
                value={Payload.country_birth}
                onChange={handlePayload}
              >
                {Countries?.map((item, i) => (
                  <option key={i} value={`${item.country}`}>
                    {item.country}
                  </option>
                ))}
              </Select>
              <Select
                _focus={{ borderColor: "blue.blue400" }}
                size={"lg"}
                borderColor={ReadOnly === false ? "#242424" : "#ddd"}
                placeholder="Place of Birth"
                fontWeight={"400"}
                fontSize={Payload.city_birth !== "" ? "16px" : "12px"}
                id="city_birth"
                value={Payload.city_birth}
                onChange={handlePayload}
              >
                {Countries?.filter(
                  (item) => item.country === Payload.country_birth
                )[0]?.cities.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Select>

              <Input
                leftIcon={<FaUserCircle />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label={`Mother's Maiden Name`}
                id="maiden_name"
                type="text"
                value={Payload.maiden_name}
                val={Payload.maiden_name !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<MdCastForEducation />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Educational Qualification"
                id="education"
                type="text"
                value={Payload.education}
                val={Payload.education !== "" ? true : false}
                onChange={handlePayload}
              />
              <Select
                _focus={{ borderColor: "blue.blue400" }}
                size={"lg"}
                borderColor={ReadOnly === false ? "#242424" : "#ddd"}
                placeholder="Select Marital Status"
                fontWeight={"400"}
                fontSize={Payload.marital_status !== "" ? "16px" : "12px"}
                id="marital_status"
                value={Payload.marital_status}
                onChange={handlePayload}
              >
                <option value={`single`}>Single</option>
                <option value={`married`}>Married</option>
                <option value={`divorce`}>Divorce</option>
                <option value={`widowed`}>Widowed</option>
                <option value={`complicated`}>Complicated</option>
              </Select>

              <Input
                leftIcon={<FaChildren />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="No of Children"
                id="no_children"
                type="number"
                value={Payload.no_children}
                val={Payload.no_children !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<GiBodyHeight />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Heights (cm)"
                id="height"
                type="text"
                value={Payload.height}
                val={Payload.height !== "" ? true : false}
                onChange={handlePayload}
              />
            </Stack>
          </Box>
          <Box w={["100%", "100%", "48%", "48%", "48%"]}>
            <Stack mt="12px" spacing="15px">
              <Input
                leftIcon={<FaPassport />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Passport No"
                id="passport_no"
                type="text"
                value={Payload.passport_no}
                val={Payload.passport_no !== "" ? true : false}
                onChange={handlePayload}
              />

              <Input
                leftIcon={<FaCalendarAlt />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Passport Exp Date"
                id="passport_date"
                type="date"
                value={Payload.passport_date}
                val={Payload.passport_date !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<MdConfirmationNumber />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="National Identity No"
                id="nin"
                type="number"
                value={Payload.nin}
                val={Payload.nin !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<BsFillTelephoneInboundFill />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Phone No"
                id="phone"
                type="number"
                value={Payload.phone}
                val={Payload.phone !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<FaLocationPin />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Address in Nigeria "
                id="address_nigeria"
                type="text"
                value={Payload.address_nigeria}
                val={Payload.address_nigeria !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<FaLocationPin />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Address in country of Resident"
                id="current_location"
                type="text"
                value={Payload.current_location}
                val={Payload.current_location !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<FaUser />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Next of Kin name "
                id="kin_name"
                type="text"
                value={Payload.kin_name}
                val={Payload.kin_name !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<FaLocationPin />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Next of Kin address "
                id="kin_address"
                type="text"
                value={Payload.kin_address}
                val={Payload.kin_address !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<BsFillTelephoneInboundFill />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Next of Kin phone "
                id="kin_phone"
                type="number"
                value={Payload.kin_phone}
                val={Payload.kin_phone !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<MdEmail />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Next of Kin email "
                id="kin_email"
                type="email"
                value={Payload.kin_email}
                val={Payload.kin_email !== "" ? true : false}
                onChange={handlePayload}
              />
              <Input
                leftIcon={<FaUserFriends />}
                bColor={ReadOnly === false ? "#242424" : "#ddd"}
                borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                readOnly={ReadOnly}
                label="Relationship "
                id="relationship"
                type="text"
                value={Payload.relationship}
                val={Payload.relationship !== "" ? true : false}
                onChange={handlePayload}
              />

              {Payload.signature ? (
                <>
                  <label>Signature</label>
                  <Image src={`${Payload.signature}`} w="200px" />
                </>
              ) : (
                <Input
                  leftIcon={<FaFileSignature />}
                  bColor={ReadOnly === false ? "#242424" : "#ddd"}
                  borderColor={ReadOnly === false ? "#017CC2" : "#ddd"}
                  readOnly={ReadOnly}
                  label="Upload signature "
                  id="signature"
                  type="file"
                  onChange={handleImageUpload}
                />
              )}
            </Stack>
          </Box>
        </Flex>
        {!ReadOnly && (
          <Button isLoading={Loading} isSubmit={true} mt={"32px"}>
            Submit
          </Button>
        )}
      </form>
    </Box>
  );
}
