import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Button from '../Button'
import { CreateAdminAPI, CreateReservationAPI, CreateStaffAPI, GetEmbassy } from '../../Utils/ApiCalls';
import { toast } from 'react-toastify';
import Input from '../Input';
import { AiTwotoneMail } from 'react-icons/ai';


export default function CreateStaff({ isOpen, onClose, type }) {
    const [Payload, setPayload] = useState({
        embassy: "",
        email: ""
    });
    const [EmbassyList, setEmbassyList] = useState([]);
    const [Loading, setLoading] = useState(false);

    const handlePayload = (e) => {
        setPayload({
            ...Payload, [e.target.id]: e.target.value
        })
    }

    const proceed = async (e) => {
        e.preventDefault()

        setLoading(true)
        try {

            const result = await CreateStaffAPI({email: Payload.email}, Payload.embassy);
            console.log("CreateReservation", result);
            setLoading(false)
            if (result.status === 200) {

                toast.success("Staff has been created successfully");
                onClose()
            } else {

            }
        } catch (error) {
            setLoading(false)

            toast.error(error.message);
        }


    }
    const CreateAdmins = async (e) => {
        e.preventDefault()

        setLoading(true)
        try {

            const result = await CreateAdminAPI({email: Payload.email});
            console.log("CreateAdminAPI", result);
            setLoading(false)
            if (result.status === 200) {

                toast.success("Admin has been created successfully");
                onClose()
            } else {

            }
        } catch (error) {
            setLoading(false)

            toast.error(error.message);
        }


    }



    const embassyList = async () => {
        try {
            const result = await GetEmbassy();
            // console.log("embassyList", result);
            if (result.status === 200) {
                setEmbassyList(result.data)
            } else {

            }
        } catch (error) {

            toast.error(error.message);
        }
    }






    useEffect(() => {
        embassyList()
    }, [isOpen]);


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create {type}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Box>


            {
                type === "Staff" && (
                    
                    <form onSubmit={proceed}>
                            <Stack spacing={'22px'}>
                                <Select _focus={{ borderColor: "blue.blue400" }} size={"lg"} borderColor={"#242424"} placeholder='Select Embassy' fontWeight={"400"} fontSize={Payload.embassy !== "" ? "16px" : "12px"} id='embassy' value={Payload.embassy} onChange={handlePayload}>

                                    {
                                        EmbassyList?.map((item, i) => (

                                            <option key={i} value={`${item.id}`}>{item.address}, {item.country}</option>

                                        ))
                                    }


                                </Select>

                                <Input leftIcon={<AiTwotoneMail />} id="email" label='Staff Email' value={Payload.email} val={Payload.email !== "" ? true : false} onChange={handlePayload} />

                            </Stack>

                            <Button mt="22px" isLoading={Loading} isSubmit={true}>Create</Button>
                        </form>
                )
            }

            {
                type === "Admin" && (

                    <form onSubmit={CreateAdmins}>
                            <Stack spacing={'22px'}>
                               

                                <Input leftIcon={<AiTwotoneMail />} id="email" label='Admin Email' value={Payload.email} val={Payload.email !== "" ? true : false} onChange={handlePayload} />

                            </Stack>

                            <Button mt="22px" isLoading={Loading} isSubmit={true}>Create</Button>
                        </form>
                )
            }
                     

                    </Box>

                </ModalBody>


            </ModalContent>

        </Modal>
    )
}
