import { Avatar, Box, Flex, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { GetInstanceAPI } from '../Utils/ApiCalls';
import { useEffect } from 'react';
import { useState } from 'react';

export default function UserInstance({ Data }) {


    console.log("DataUserInstance", Data)
    return (
        <Flex justifyContent={"center"}>
            <Box rounded={"8px"} mt="32px" p="20px" boxShadow={"4px 6px 12px 3px #B5B5B5 "} w={["90%", "90%", "80%", "70%", "65%",]}>
                <Flex justifyContent={"space-between"} flexWrap={"wrap-reverse"} alignContent={"stretch"}>

                    <Box w={["100%", "100%", "48%", "32%", "32%",]}>
                        <Stack spacing={"10px"}>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Surname</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.last_name}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Sex</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.gender}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Height(cm)</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.height}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Address</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.address_nigeria}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Current Location</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.current_location}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Community</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.community}</Text>
                            </Box>
                        </Stack>
                    </Box>
                    <Box w={["100%", "100%", "48%", "32%", "32%",]}>
                        <Stack spacing={"10px"}>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Given names</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.first_name}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Date of birth</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.dob}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Country of birth</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.country_birth}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>State of birth</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.city_birth}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>NIN</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.nin}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight={"700"} fontSize={"12"}>Marital status</Text>
                                <Text fontWeight={"500"} fontSize={"18px"}>{Data?.profile_data?.marital_status}</Text>
                            </Box>
                        </Stack>
                    </Box>
                    <Box w={["100%", "100%", "48%", "32%", "32%",]} >
                            <Flex justifyContent={"center"} alignItems={"center"} alignContent={"center"} h={"90%"}>
                            <Avatar src={`${Data?.image_data?.image}`} size={"2xl"}/>
                            </Flex>
                    </Box>

                </Flex>
            </Box>

        </Flex>
    )
}

