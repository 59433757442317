import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Button from "../Components/Button";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import Input from "../Components/Input";
import logo from "../assest/logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  let date = new Date();
  return (
    <Box bg="#242424" px="3%" py="10px" pb="32px">
      <Flex
        justifyContent={"space-between"}
        mt="32px"
        flexWrap={"wrap"}
        mb="32px"
      >
        <Box
          w={["100%", "100%", "100%", "32%", "32%"]}
          display={["block", "block", "block", "block", "block"]}
        >
          <Center>
            <Image pos={"relative"} left="-15px" w="25%" src={logo} />
          </Center>
          <Box
            mt="12px"
            fontSize={"14px"}
            textAlign={["center", "center", "center", "left"]}
            fontWeight={"400"}
            color={"blue.blue500"}
          >
            Address :{" "}
            <Box as="span" color="#fff" fontWeight={"700"}>
              Suite 402 – 403 Bank of Industry (BOI) No 18 Muhammad Buhari Way
              Kaduna Nigeria
            </Box>
          </Box>
          <Box
            mt="12px"
            fontSize={"14px"}
            textAlign={["center", "center", "center", "left"]}
            fontWeight={"400"}
            color={"blue.blue500"}
          >
            Working Days/Hours :{" "}
            <Box as="span" color="#fff" fontWeight={"700"}>
              Mon - Sun / 9:00 AM - 8:00 PM
            </Box>
          </Box>
        </Box>

        <Box w={["100%", "100%", "100%", "32%", "32%"]} textColor={"#fff"}>
          <Box
            textTransform={"uppercase"}
            textAlign={["center", "center", "center", "left"]}
            mt={["10px", 0]}
            fontWeight={"700"}
          >
            Talk with us
          </Box>
          <Box
            mt="32px"
            fontSize={"14px"}
            textAlign={["center", "center", "center", "left"]}
            fontWeight={"400"}
            color={"blue.blue500"}
          >
            Let's talk !{" "}
            <Box as="span" color="#fff" fontWeight={"700"}>
              info@e-consularcard.com
            </Box>
          </Box>
          <Box
            mt="10px"
            fontSize={"14px"}
            textAlign={["center", "center", "center", "left"]}
            fontWeight={"400"}
            color={"blue.blue500"}
          >
            or Call!{" "}
            <Box as="span" color="#fff" fontWeight={"700"}>
              +234(80) 28533333{" "}
            </Box>
          </Box>
          <Flex justifyContent={["center", "center", "center", "left"]}>
            <HStack mt={"22px"}>
              <Box
                color="blue.blue500"
                fontSize={"20px"}
                mx="2px"
                cursor="pointer"
              >
                <FaFacebook />
              </Box>
              <Box
                color="blue.blue500"
                fontSize={"20px"}
                mx="2px"
                cursor="pointer"
              >
                <FaInstagram />
              </Box>
              <Box
                color="blue.blue500"
                fontSize={"20px"}
                mx="2px"
                cursor="pointer"
              >
                <FaTwitter />
              </Box>
            </HStack>
          </Flex>
        </Box>
        <Box w={["100%", "100%", "100%", "32%", "32%"]} color={"#fff"}>
          <Box
            textTransform={"uppercase"}
            textAlign={["center", "center", "center", "left"]}
            mt={["20px", "20px", "20px", 0]}
            fontWeight={"700"}
          >
            Other Links
          </Box>

          <Stack
            color={"blue.blue500"}
            mt="32px"
            cursor={"pointer"}
            textAlign={["center", "center", "center", "left"]}
          >
            <Link to="/terms-and-conditions">
              <Text>Terms & conditions</Text>
            </Link>
            <Link to="/faq">
            <Text>FAQs</Text>
            </Link>
            
            <Text>Community Leaders</Text>
          </Stack>
        </Box>
      </Flex>

      <Divider />
      <Box
        display={"flex"}
        flexDirection={{ base: "column-reverse", md: "row" }}
        gap={{ base: "10px" }}
        justifyContent={{ base: "center", md: "center" }}
        alignItems={"center"}
        mt="25px"
      >
        <Box>
          <Text
            textTransform={"capitalize"}
            fontSize={{ base: "12px", md: "14px" }}
            color="#fff"
            textAlign={"center"}
          >
            Copyright © {date.getFullYear()}. Comprehensive Data Management
            System, Proudly powered by Dannon Group
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
